import React from "react";

const WheatBarn = () => {
  return (
    <div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>TYPES</th>
            <th>COARSE BRAN</th>
            <th>FINE BARN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MOISTURE</td>
            <td>14% Max</td>
            <td>14% Max</td>
          </tr>
          <tr>
            <td>PROTEIN</td>
            <td>15% Max</td>
            <td>12% Max</td>
          </tr>
          <tr>
            <td>FIBER</td>
            <td>12% Min</td>
            <td>12% Min</td>
          </tr>
          <tr>
            <td>ACID INSOLUBLE ASH</td>
            <td>0.5% Max</td>
            <td>0.5% Max</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={2}>
              Multi wall plain new PP bags of 25 kgs or 50 Kgs
            </td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td colSpan={2}>18 MT per 20 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WheatBarn;
