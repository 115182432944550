import { Image } from "@chakra-ui/react";
import React from "react";
import certi1 from "../../images/certificates/certi1.png";
import certi2 from "../../images/certificates/certi2.jpg";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import spiceBoardLogo from "../../images/certificates/certi1.png";
import apedaLogo from "../../images/certificates/certi2.jpg";
import certi3 from "../../images/certificates/certi3.png";
import certi4 from "../../images/certificates/certi4.png";
import certi5 from "../../images/certificates/certi5.jpg";
import certi6 from "../../images/certificates/certi6.png";
import certi7 from "../../images/certificates/certi7.png";
import certi8 from "../../images/certificates/certi8.png";
import certi9 from "../../images/certificates/certi9.jpg";
import certi10 from "../../images/certificates/certi10.jpg";
import certi11 from "../../images/certificates/certi11.jpg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Certificates = () => {
  return (
    <div className="">
      <div className=" lg:w-9/12 w-10/12 mx-auto py-24">
        <div className=" w-full text-center font-bold text-[40px] font-inter mb-20 md:mb-20">
          Certificates
        </div>
        <div className="lg:w-9/12 w-10/12 mx-auto h-auto">
          {/* <Zoom fraction={0.3} triggerOnce={true}>
                    <div className='w-full flex-col sm:flex-row flex gap-10 justify-between items-center'>
                        <Image src={certi1} alt='certi1' className='sm:w-[40%] mb-10 sm:mb-0'/>
                        <Image src={certi2} alt='certi1' className='sm:w-[40%]'/>
                    </div>
                </Zoom> */}

          <Swiper
            slidesPerView={4}
            spaceBetween={80}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]}
            // navigation
            pagination={{ clickable: true}}
            // scrollbar={{ draggable: true }}
            breakpoints={{
              100: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src={spiceBoardLogo}
                alt="Spices Board India"
                className="mx-auto mb-4"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img src={apedaLogo} alt="APEDA" className="mx-auto mb-4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={certi3} alt="APEDA" className="mx-auto mb-4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={certi4} alt="APEDA" className="mx-auto mb-4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={certi5} alt="APEDA" className="mx-auto mb-4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={certi6} alt="APEDA" className="mx-auto mb-4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={certi7} alt="APEDA" className="mx-auto mb-4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={certi8} alt="APEDA" className="mx-auto mb-4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={certi9} alt="APEDA" className="mx-auto mb-4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={certi10} alt="APEDA" className="mx-auto mb-4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={certi11} alt="APEDA" className="mx-auto mb-4" />
            </SwiperSlide>
          </Swiper>

          <div className=" text-center underline mt-20 font-inter text-sm">
            <Link to={"/certificates"}>Know more</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificates;
