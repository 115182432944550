import React from 'react'

const Castor = () => {
  return (
    <div>
        <table className="responsive-table">
        <thead>
          <tr>
            <th>CASTOR SEEDS</th>
            <th>SPECS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MOISTURE</td>
            <td>8%</td>

          </tr>
          <tr>
            <td>OIL CONTENT</td>
            <td>47% Min</td>

          </tr>
          <tr>
            <td>PROTEIN</td>
            <td>29% MIN</td>

          </tr>
          <tr>
            <td>PURITY</td>
            <td>96%</td>

          </tr>
          <tr>
            <td>NITROGEN</td>
            <td>4%</td>

          </tr>
          <tr>
            <td>FIBER</td>
            <td>16% MIN</td>

          </tr>
          <tr>
            <td>LOADING</td>
            <td >19 MT in 20 FT and 26 MT in 40 FT container</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Castor