import React, { useEffect } from 'react';
import './parallax.css'
import { Fade } from 'react-awesome-reveal';
import { Link, animateScroll as scroll } from 'react-scroll';

const ParallaxImage = ({ src }) => {
  useEffect(() => {
    const handleScroll = () => {
      const parallaxElements = document.querySelectorAll('.parallax');
      parallaxElements.forEach((element) => {
        const speed = element.getAttribute('data-speed') || 0.5;
        const yPos = -(window.scrollY * speed);
        element.style.transform = `translate3d(0px, ${yPos}px, 0px)`;
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  

  return (
    <div className="relative overflow-hidden h-[800px]">
      <img
        src={src}
        alt="1"
        className="absolute top-0 left-0 w-full h-full object-cover object-center"
        data-speed="0.5"
        loading="eager"
        style={{ filter: 'blur(2px)' }}
      />
      <div className=" absolute z-10 w-full h-full bg-black bg-opacity-60 top-0 left-0"></div>
        <div className=" absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] z-20 text-[28px] sm:text-[35px] lg:text-[50px] font-inter font-bold text-white md:w-8/12 w-11/12 flex flex-col">
          {/* <div className=" text-base font-normal">
            <p className=" italic">Call us </p>
            <p className=" text-2xl mb-4">+91 9724704297</p>
          </div> */}
            <div>
              <p className=" md:-mb-3  text-[24px] sm:text-[31px] lg:text-[46px] ">
                <Fade direction="right" delay={200} duration={500} triggerOnce={true}>WELCOME TO</Fade>
              </p>
              <h1><Fade direction="right" delay={1000} duration={1000} triggerOnce={true}>JAY SOMNATH TRADERS</Fade></h1>
              <h2 className='text-xl font-inter font-light text-left mt-2'><Fade direction='right' delay={1000} duration={1500} triggerOnce={true}>Globally Import And Export Company</Fade></h2>
              {/* <Link to='section1' smooth={true} duration={1000}>
                <button
                  className="w-[180px] text-center mt-4 mb-5 h-[48px] bg-yellow-300 rounded-lg text-richblack-900 font-inter text-lg font-normal"
                >
                  Get price quote
                </button>
              </Link> */}
            </div>
          {/* <Button
            onClick={() => navigate("/contact-us")}
            colorScheme="yellow"
            size="lg"
            maxW={"200px"}
            fontWeight={"400"}
            className="mt-4"
          >
            CONTACT US
          </Button> */}
        </div>
    </div>
  );
};

export default ParallaxImage;