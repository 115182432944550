import React from 'react'

const TurmericFinger = () => {
  return (
    <div>
        <table className="responsive-table">
        <thead>
          <tr>
            <th>TURMERIC FINGER</th>
            <th>SPECS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TYPE</td>
            <td>Finger/Bulbs – Polished Or Unpolished</td>
          </tr>
          <tr>
            <td>FLEXIBILITY</td>
            <td>Hard</td>
          </tr>
          <tr>
            <td>FOREIGN MATTER</td>
            <td>1%</td>
          </tr>
          <tr>
            <td>DEFECTIVES</td>
            <td>3%</td>
          </tr>
          <tr>
            <td>MOISTURE</td>
            <td>10%</td>
          </tr>
          <tr>
            <td>TOTAL ASH</td>
            <td>7%</td>
          </tr>
          <tr>
            <td>LEAD AS PPB</td>
            <td>2.5</td>
          </tr>
          <tr>
            <td>CHRROMATE TEST</td>
            <td>NEGATIVE</td>
          </tr>
          <tr>
            <td>ACID INSOLUABLE ASH</td>
            <td>2.5%</td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td>13 MT in 20 FT and 26 MT in 40 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default TurmericFinger