import React from 'react';

const BasmatiRice = () => {
  return (
    <div className=' flex lg:flex-row flex-col gap-10'>
        <table className="responsive-table">
        <thead>
          <tr>
            <th>RICE</th>
            <th>1121</th>
            <th>1509</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Length</td>
            <td>8.3 MM</td>
            <td>8.3 MM</td>
          </tr>
          <tr>
            <td>Moisture</td>
            <td>14% MAX</td>
            <td>14% MAX</td>
          </tr>
          <tr>
            <td>Discoloured</td>
            <td>0.5% MAX</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Broken</td>
            <td>0.5% MAX</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Purity Of Rice</td>
            <td>92%</td>
            <td>92%</td>
          </tr>
          <tr>
            <td>Packing</td>
            <td colSpan={2}>
              5kg,10kg, 25kg, 40kg, 50kg bag in PP Bag, Jute Bag, Non Woven Bag Or As Per Buyer Requirement.
            </td>
          </tr>
          <tr>
            <td>Stuffing</td>
            <td colSpan={2}>24 MT in 20 FT</td>
          </tr>
          <tr>
            <td>Type</td>
            <td colSpan={2}>WHITE SELLA, GOLDEN SELLA, STEAM SELLA</td>
          </tr>
        </tbody>
      </table>

      <table className="responsive-table">
        <thead>
          <tr>
            <th>RICE</th>
            <th>Sugandha</th>
            <th>Pusa</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Length</td>
            <td>7.5 MM</td>
            <td>7.5 MM</td>
          </tr>
          <tr>
            <td>Moisture</td>
            <td>14% MAX</td>
            <td>14% MAX</td>
          </tr>
          <tr>
            <td>Discoloured</td>
            <td>0.5% MAX</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Broken</td>
            <td>0.5% MAX</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Purity Of Rice</td>
            <td>92%</td>
            <td>92%</td>
          </tr>
          <tr>
            <td>Packing</td>
            <td colSpan={2}>
              5kg,10kg, 25kg, 40kg, 50kg bag in PP Bag, Jute Bag, Non Woven Bag Or As Per Buyer Requirement.
            </td>
          </tr>
          <tr>
            <td>Stuffing</td>
            <td colSpan={2}>24 MT in 20 FT</td>
          </tr>
          <tr>
            <td>Type</td>
            <td colSpan={2}>WHITE SELLA, GOLDEN SELLA, STEAM SELLA</td>
          </tr>
        </tbody>
      </table>

      <table className="responsive-table">
        <thead>
          <tr>
            <th>RICE</th>
            <th>Sharbati</th>
            <th>PR – 11</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Length</td>
            <td>7-7.20 MM</td>
            <td>6.20 MM</td>
          </tr>
          <tr>
            <td>Moisture</td>
            <td>14% MAX</td>
            <td>14% MAX</td>
          </tr>
          <tr>
            <td>Discoloured</td>
            <td>0.5% MAX</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Broken</td>
            <td>0.5% MAX</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Purity Of Rice</td>
            <td>92%</td>
            <td>92%</td>
          </tr>
          <tr>
            <td>Packing</td>
            <td colSpan={2}>
              5kg,10kg, 25kg, 40kg, 50kg bag in PP Bag, Jute Bag, Non Woven Bag Or As Per Buyer Requirement.
            </td>
          </tr>
          <tr>
            <td>Stuffing</td>
            <td colSpan={2}>24 MT in 20 FT</td>
          </tr>
          <tr>
            <td>Type</td>
            <td colSpan={2}>WHITE SELLA, GOLDEN SELLA, STEAM SELLA</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BasmatiRice;