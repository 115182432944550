import React , {useRef , useState} from 'react';
import contactus from '../images/contact/git.jpg';
import {
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button,
} from '@chakra-ui/react';
import { IoCall } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { Slide } from 'react-awesome-reveal';
import toast from "react-hot-toast";
import emailjs from '@emailjs/browser';
import { useForm } from "react-hook-form";

const Contact = () => {

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors, isSubmitSuccessful },
    } = useForm();

    const [loading , setLoading] = useState(false);
    const [userName , setUserName] = useState("");
    const [userEmail , setUserEmail] = useState("");
    const [userNumber , setUserNumber] = useState("");
    const form = useRef();

    const sendEmail = (e) => {
        setLoading(true);
        emailjs
          .sendForm('service_lkkwpkn', 'template_luwn16e', form.current, {
            publicKey: 'yz8M07mgK3x_jJcl_',
          })
          .then(
            () => {
              toast.success("Enquiry Sent!");
              console.log('SUCCESS!');
              setLoading(false);
              reset();
            },
            (error) => {
              toast.error("An error occured");
              console.log('FAILED...', error.text);
              setLoading(false);
            },
          );
      };

  return (
    <div>
        {/* <div className=' w-full h-[80px]'></div> */}
        <div className=' w-full h-[450px] relative'>
            <img src={contactus} alt='about us' className=' h-[450px] object-cover bg-center w-full overflow-hidden' loading="eager" style={{ filter: 'blur(5px)' }}/>
            <div className='absolute top-0 left-0 w-full h-full bg-richblack-700 bg-opacity-60'>
                <div className=' md:w-8/12 w-10/12 mx-auto text-white font-inter font-bold text-4xl flex flex-col justify-center h-full'>
                    <Slide triggerOnce={true} delay={200}>Get in Touch</Slide>
                    <p className=' text-base font-normal mt-6'><Slide triggerOnce={true} delay={200}>Want to get in Touch? We'd love to hear from you. Here''s how you can reach us.</Slide></p>
                </div>
            </div>
        </div>

        <div className=' md:w-8/12 w-11/12 mx-auto my-16 relative'>
            <div className=' flex lg:flex-row flex-col justify-between  gap-x-20 gap-y-20 font-inter  -translate-y-[150px] '>
                <div className='w-full lg:w-[50%] text-justify bg-white p-5 sm:p-10 rounded-md text-richblack-900 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]'>
                    {/* <div className=' font-bold text-3xl text-blue-700 mb-10'>Keep in touch</div> */}
                    <div>
                        <p className='mb-5'>We operate a business built on trust. This can only achieved through communication and experience support. From the first contact past you’re more and more anniversary with us.</p>
                        <br/>

                        <div className='flex gap-5'>
                            <div>
                                <IoCall className=' text-[30px] text-richblack-900 mt-1'/>
                            </div>
                            <div className='flex flex-col gap-4'>
                                <p>+91 9724704297</p>
                                <p>jay.somnathetraders@gmail.com</p>
                                <p>GSTIN : 24BQIPR9739Q1ZP</p>
                            </div>
                        </div>

                        <div className='flex gap-5 mt-10'>
                            <div>
                                <FaLocationDot className=' text-[30px] text-richblack-900'/>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <p>12, Jaldhara319 Society,</p>
                                <p>Government Primary School,</p>
                                <p>Manipur , Ahmedabad</p>
                                <p>Gujarat - 382115</p>
                            </div>
                        </div>

                        <p className='mt-8'>We are Eager to discuss your Business needs, and answer any question you may have. Enter your details and we will get back to you shortly.</p>
                    </div>
                </div>

                {/* FORM */}
                <div className='lg:w-[50%] w-full bg-white p-5 sm:p-10 rounded-md shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]'>
                <form className=' flex flex-col gap-y-2' ref={form} onSubmit={handleSubmit(sendEmail)}>
                    <FormLabel>Name <sup className=" text-pink-400 font-inter"> *</sup></FormLabel>
                    <Input type='text' variant='filled' placeholder='Enter your Name' name='name' id='name'
                        {...register("name", { required: true })}
                        // onChange={(value)=>setUserName(value)}
                        // value={userName}
                    />
                    {errors.name && <span className="text-red-500 ">Please enter your name</span>}
                    <FormLabel>Email address <sup className=" text-pink-400 font-inter"> *</sup></FormLabel>
                    <Input type='email' variant='filled' placeholder='Enter your Email' name='email' id='email'
                        {...register("email", { required: true })}
                        // onChange={(value)=>setUserEmail(value)}
                        // value={userEmail}
                    />
                    {errors.email && <span className="text-red-500 ">Please enter your email</span>}
                    <FormLabel>Phone no <sup className=" text-pink-400 font-inter"> *</sup></FormLabel>
                    <Input type='number' variant='filled' placeholder='Enter your Contact no' name='contactNumber' id='contactNumber'
                        {...register("contactNumber", { required: true })}
                        // onChange={(value)=>setUserNumber(value)}
                        // value={userNumber}
                    />
                    {errors.contactNumber && <span className="text-red-500 ">Please enter your Contact no</span>}
                    <FormLabel>Message</FormLabel>
                    <Textarea placeholder='Type your message here...' name='message' id='message'/>
                    {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
                    <button colorScheme='blue' className='mt-5 bg-richblack-800 rounded-md text-white px-5 py-2' type='submit'>
                        {loading ? "Sending..." : "Submit"}
                    </button>
                </form>
                </div>
            </div>

            <div><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=12,%20Jaldhara319%20Society,%20%20Government%20Primary%20School,%20%20Manipur%20,%20Ahmedabad+(Jay%20Somnath%20Traders)&amp;t=p&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps devices</a></iframe></div>
        </div>
        
    </div>
  );
};

export default Contact;