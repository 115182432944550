import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fruits } from "../data/productsData";
import { vegetables } from "../data/productsData";
import { grains } from "../data/productsData";
import { oilSeed } from "../data/productsData";
import { rice } from "../data/productsData";
import { cattleFeed } from "../data/productsData";
import { groundSpices } from "../data/productsData";
import { wholeSpices } from "../data/productsData";
import { flourProducts } from "../data/productsData";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdKeyboardArrowRight } from "react-icons/md";
import './breadcrump.css';

const data = {
  fruits,
  vegetables,
  grains,
  oilSeed,
  rice,
  cattleFeed,
  groundSpices,
  wholeSpices,
  flourProducts,
};

const ProductDetails = () => {
  const { category, id } = useParams();
  const productData = data[category] || [];
  const productDetails = productData?.data.find((product) => product.id == id);
  const navigate = useNavigate();

  return (
    <div>
      <div className=" w-full h-[80px]"></div>

      <div className=" w-full h-[60px] bg-richblack-800 text-richblack-25">
        <p className=" md:w-8/12 w-10/12 mx-auto font-inter flex items-center h-full breadcrumb-container">
          <Breadcrumb
            spacing="8px"
            separator={<MdKeyboardArrowRight color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink onClick={() => navigate("/")}>
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink>Products</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink onClick={() => navigate(`/products/${category}`)}>
                {productData?.name}
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{productDetails?.name}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </p>
      </div>

      <div className=" md:w-8/12 w-11/12 mx-auto my-20">
        <div className=" flex lg:flex-row flex-col gap-10 justify-between items-center w-full mb-20">
          <div>
            <img
              src={productDetails?.image}
              alt={productDetails?.name}
              className=" lg:w-[800px] rounded-lg aspect-video object-coover"
            />
          </div>
          <div className=" md:w-[100%] font-inter text-lg text-richblack-800 text-justify">
            <div className=" text-3xl font-inter font-bold mb-8 text-left">
              {productDetails?.name}
            </div>
            {productDetails?.desc}
          </div>
        </div>

        {productDetails?.specs && (
          <div className=" w-full my-14 font-inter">
            <div className=" font-bold text-2xl mb-5">Product Details</div>
            <div>
              {productDetails?.specs?.varities && (
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Varities : </span>
                  {productDetails?.specs?.varities}
                </p>
              )}
              {productDetails?.specs?.weight && (
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Weight : </span>
                  {productDetails?.specs?.weight}
                </p>
              )}
              {productDetails?.specs?.taste && (
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Taste : </span>
                  {productDetails?.specs?.taste}
                </p>
              )}
              {productDetails?.specs?.availability && (
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Availability : </span>
                  {productDetails?.specs?.availability}
                </p>
              )}
              {productDetails?.specs?.packing && (
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Packing : </span>
                  {productDetails?.specs?.packing}
                </p>
              )}
              {productDetails?.specs?.size && (
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Size : </span>
                  {productDetails?.specs?.size}
                </p>
              )}
              {productDetails?.specs?.skin && (
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Skin : </span>
                  {productDetails?.specs?.skin}
                </p>
              )}
              {productDetails?.specs?.products && (
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Products : </span>
                  {productDetails?.specs?.products}
                </p>
              )}
            </div>
          </div>
        )}

        {productDetails?.table && <div>{productDetails?.table}</div>}
      </div>
    </div>
  );
};

export default ProductDetails;
