import React from 'react'

const Burghul = () => {
  return (
    <div>
      <table className="responsive-table">
          <thead>
            <tr>
              <th >BURGHUL</th>
              <th>SPECS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MOISTURE</td>
              <td>12% MAX</td>

            </tr>
            <tr>
              <td>PROTIEN</td>
              <td>12% MIN</td>

            </tr>
            <tr>
              <td>QUALITY</td>
              <td>FINE, MEDIUM, COARSE</td>

            </tr>
            <tr>
              <td>ENERGGY</td>
              <td>1500 KJ</td>

            </tr>
            <tr>
              <td>FAT</td>
              <td>1.75 G</td>

            </tr>
            <tr>
              <td>INSPECTION</td>
              <td>QSS, GEOCHEM OR AS PER REQUIREMENT</td>

            </tr>
            <tr>
              <td>PACKING</td>
              <td colSpan={1}>25 KG, 50 KG AND OTHER CONSUMER PACKING.s</td>
            </tr>
            <tr>
              <td>LOADING</td>
              <td colSpan={1}>23 MT IN 20FT CONTAINER</td>
            </tr>
          </tbody>
        </table>
    </div>
  )
}

export default Burghul