import React from 'react'
import "./table.css";

const IndianBarley = () => {
  return (
    <div>
      <div className="container">

        <div className="table1">
          <div className="row">
            <div className="cell header">MOISTURE</div>
            <div className="cell">
            14% MAX
            </div>
          </div>
          <div className="row">
            <div className="cell header">FOREIGN MATTER</div>
            <div className="cell">
            2% MAX
            </div>
          </div>
          <div className="row">
            <div className="cell header">OTHER FOOD GRAINS</div>
            <div className="cell">
            1% MAX
            </div>
          </div>
          <div className="row">
            <div className="cell header">DAMAGED GRAINS</div>
            <div className="cell">
            2-3% MAX
            </div>
          </div>
          <div className="row">
            <div className="cell header">SHRIVELLED & IMMATURE GRAINS</div>
            <div className="cell">
            5% MAX
            </div>
          </div>
          <div className="row">
            <div className="cell header">WEEVILED GRAINS</div>
            <div className="cell">
            2% MAX
            </div>
          </div>
          <div className="row">
            <div className="cell header">INSPECTION</div>
            <div className="cell">
            QSS OR ANY OTHER AGENCY AS PER BUYER REQUIRED.
            </div>
          </div>
          <div className="row">
            <div className="cell header">PACKING</div>
            <div className="cell">
            5kg,10kg, 25kg,40kg,50kg bag in PP Bag
            </div>
          </div>
          <div className="row">
            <div className="cell header">LOADING</div>
            <div className="cell">
            22 MT IN 20FT CONTAINER
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndianBarley