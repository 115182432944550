import React from "react";

const Sesame = () => {
  return (
    <div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>GRADE</th>
            <th>99.9%</th>
            <th>99.5%</th>
            <th colSpan={2}>99%</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>PROCESS</td>
            <td>SORTEX</td>
            <td>SORTEX/MACHINE</td>
            <td colSpan={2}>MACHINE</td>
          </tr>
          <tr>
            <td>COLOR</td>
            <td>NATURAL WHITE</td>
            <td>NATURAL WHITE</td>
            <td>NATURAL WHITE</td>
            <td>BLACK</td>
          </tr>
          <tr>
            <td>FFA (AS OLEIC ACID)</td>
            <td colSpan={4}>Below 3%</td>
          </tr>
          <tr>
            <td>MOISTURE</td>
            <td colSpan={4}>Below 7%</td>
          </tr>
          <tr>
            <td>OIL CONTAINT</td>
            <td colSpan={4}>48% (Approx)</td>
          </tr>
          <tr>
            <td>INSPECTION</td>
            <td colSpan={4}>QSS, SGS, OR AS PER BUYER REQUIRED</td>
          </tr>
          <tr>
            <td>ORIGIN</td>
            <td colSpan={4}>INDIA</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={4}>
              MULTIWALL PAPER OR POLY- PROPYLENE (PP) BAGS 50 KG; 25 KG & 50 LB.
              ALSO IN 5 & 10 LB POLY BAGS
            </td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td colSpan={4}>19 MT in 20 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Sesame;
