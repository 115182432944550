import React from "react";

const BrokenRice = () => {
  return (
    <div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>RICE</th>
            <th>25%</th>
            <th>50%</th>
            <th>100%</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Moisture</td>
            <td>14%</td>
            <td>14%</td>
            <td>14%</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>White</td>
            <td>White</td>
            <td>White</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>Raw/<br/>parboiled</td>
            <td>Raw/<br/>parboiled</td>
            <td>Raw/<br/>parboiled</td>
          </tr>
          <tr>
            <td>Black Grains</td>
            <td>Nil</td>
            <td>Nil</td>
            <td>Nil</td>
          </tr>
          <tr>
            <td>Damage/<br/>Discolour</td>
            <td>2%</td>
            <td>2%</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>Packing</td>
            <td colSpan={4}>
              Jute Bags Or Non-Woven Bags In Master Pp Bag Or, As Per Buyer
              Requirement.
            </td>
          </tr>
          <tr>
            <td>Container Stuffing</td>
            <td colSpan={4}>26 MT in 20 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BrokenRice;
