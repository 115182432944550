import React from 'react'

const CorianderSeeds = () => {
  return (
    <div>
      <table className="responsive-table">
          <thead>
            <tr>
              <th colSpan={3}>Coriander Seeds Whole Eagle </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>QUALITY</td>
              <td>98%</td>
              <td>99.5%</td>
            </tr>
            <tr>
              <td>PURITY</td>
              <td>98%</td>
              <td>99.5%</td>
            </tr>
            <tr>
              <td>ADMIXTURE</td>
              <td>2%</td>
              <td>0.5%</td>
            </tr>
            <tr>
              <td>CLEANING</td>
              <td>Machine cleaned</td>
              <td>Sortex cleaned</td>
            </tr>
            <tr>
              <td>MOISTURE</td>
              <td>8%</td>
              <td>8%</td>
            </tr>
          </tbody>
        </table>
    </div>
  )
}

export default CorianderSeeds