import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fruits } from "../data/productsData";
import { vegetables } from "../data/productsData";
import { grains } from "../data/productsData";
import { oilSeed } from "../data/productsData";
import { rice } from "../data/productsData";
import { cattleFeed } from "../data/productsData";
import { groundSpices } from "../data/productsData";
import { wholeSpices } from "../data/productsData";
import { flourProducts } from "../data/productsData";
import ProductsThumbnail from "../components/products/ProductsThumbnail";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdKeyboardArrowRight } from "react-icons/md";
import './breadcrump.css';

const data = {
  fruits,
  vegetables,
  grains,
  oilSeed,
  rice,
  cattleFeed,
  groundSpices,
  wholeSpices,
  flourProducts,
};

const Products = () => {

  const { category } = useParams();
  const productData = data[category] || [];
  const navigate = useNavigate();

  return (
    <div>
      <div className=" w-full h-[80px]"></div>

      <div className=" w-full h-[60px] bg-richblack-800 text-richblack-25">
        <p className=" md:w-8/12 w-10/12 mx-auto font-inter flex items-center h-full breadcrumb-container">
        <Breadcrumb spacing='8px' separator={<MdKeyboardArrowRight color='gray.500' />}>
            <BreadcrumbItem>
                <BreadcrumbLink onClick={()=>navigate("/")}>Home</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
                <BreadcrumbLink>Products</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>{productData?.name}</BreadcrumbLink>
            </BreadcrumbItem>
            </Breadcrumb>
        </p>
      </div>

      <div className=" md:w-8/12 w-10/12 mx-auto my-20">
        <div className=" flex lg:flex-row flex-col gap-10 justify-between items-center w-full mb-20">
          <div>
            <img
              src={productData?.image}
              alt={productData?.name}
              className=" lg:w-[800px] rounded-lg aspect-video object-coover"
            />
          </div>
          <div className=" md:w-[100%] font-inter text-lg text-richblack-800 text-justify">
            <div className=" text-3xl font-inter font-bold mb-8 text-left">
              Our {productData?.name} products
            </div>
            {productData?.description}
          </div>
        </div>
        <div className=" grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 place-items-center place-content-center gap-10 items-stretch">
          {productData?.data.map((product) => {
            return (
              <ProductsThumbnail
                cardTitle={product?.name}
                image={product?.image}
                desc={product?.desc}
                category={category}
                id={product?.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Products;
