import React from 'react';

const WheatFlour = () => {
  return (
    <div>
        <table className="responsive-table">
          <thead>
            <tr>
              <th rowSpan={2}>TYPE</th>
              <th >WHOLE WHEAT FLOUR</th>
              <th>FINE WHEAT FLOUR</th>
            </tr>
            <tr>
                <th >(CHAKKI ATTA)</th>
                <th>(MAIDA)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MOISTURE</td>
              <td>14% MAX</td>
              <td>14% MAX</td>

            </tr>
            <tr>
              <td>PROTIEN</td>
              <td>11%</td>
              <td>11-13%</td>

            </tr>
            <tr>
              <td>COLOR</td>
              <td>Creamish white</td>
              <td>White</td>

            </tr>
            <tr>
              <td>ASH</td>
              <td>1.20% MAX</td>
              <td>0.65% MAX</td>

            </tr>
            <tr>
              <td>WET GLUTEN</td>
              <td>27% MIN</td>
              <td>27% MIN</td>

            </tr>
            <tr>
              <td>DRY GLUTEN</td>
              <td>8% MIN</td>
              <td>8.5% MIN</td>

            </tr>
            <tr>
              <td>PACKING</td>
              <td colSpan={2}>Multi wall plain new PP bags of 25 kgs or 50 Kgs</td>
            </tr>
            <tr>
              <td>LOADING</td>
              <td colSpan={2}>23 MT per 20 FT</td>
            </tr>
          </tbody>
        </table>
    </div>
  )
};

export default WheatFlour;