import React from "react";
import "./yellowmaize.css";

const YellowMaize = () => {
  return (
    <div>
      <div className="container flex lg:flex-row flex-col gap-10">
        <table className="responsive-table">
          <thead>
            <tr>
              <th rowSpan="2">MAIZE</th>
              <th colSpan="2">YELLOW MAIZE</th>
            </tr>
            <tr>
              <th>Hybrid/ Big Grain</th>
              <th>Desi/ Small Grain</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>QUALITY</td>
              <td>Hybrid/ Big Grain</td>
              <td>Desi/ Small Grain</td>
            </tr>
            <tr>
              <td>MOISTURE</td>
              <td>14% MAX</td>
              <td>14% MAX</td>
            </tr>
            <tr>
              <td>MOISTURE</td>
              <td>14% Max</td>
              <td>14% Max</td>
            </tr>
            <tr>
              <td>WEEVILED DAMAGED</td>
              <td>2% Max</td>
              <td>2% Max</td>
            </tr>
            <tr>
              <td>PROTEIN</td>
              <td>8% MIN</td>
              <td>8% MIN</td>
            </tr>
            <tr>
              <td>AFLATOXIN</td>
              <td>20PPB MAX</td>
              <td>20PPB MAX</td>
            </tr>
            <tr>
              <td>BROKEN KERNALS</td>
              <td>3% MAX</td>
              <td>3% MAX</td>
            </tr>
            <tr>
              <td>IMPURITIES</td>
              <td>2% MAX</td>
              <td>2% MAX</td>
            </tr>
            <tr>
              <td>GRAIN ADMIXTURE</td>
              <td>2% MAX</td>
              <td>2% MAX</td>
            </tr>
            <tr>
              <td>PACKING</td>
              <td colSpan={2}>25kg,40kg,50kg bag in PP Bag</td>
            </tr>
            <tr>
              <td>LOADING</td>
              <td colSpan={2}>24 MT IN 20 FT</td>
            </tr>
          </tbody>
        </table>
        <table className="responsive-table">
          <thead>
            <tr>
              <th rowSpan="2">MAIZE</th>
              <th colSpan="2">WHITE MAIZE</th>
            </tr>
            <tr>
              <th>Hybrid/ Big Grain</th>
              <th>Desi/ Small Grain</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>QUALITY</td>
              <td>Hybrid/ Big Grain</td>
              <td>Desi/ Small Grain</td>
            </tr>
            <tr>
              <td>MOISTURE</td>
              <td>14% MAX</td>
              <td>14% MAX</td>
            </tr>
            <tr>
              <td>MOISTURE</td>
              <td>14% Max</td>
              <td>14% Max</td>
            </tr>
            <tr>
              <td>WEEVILED DAMAGED</td>
              <td>2% Max</td>
              <td>2% Max</td>
            </tr>
            <tr>
              <td>PROTEIN</td>
              <td>8% MIN</td>
              <td>8% MIN</td>
            </tr>
            <tr>
              <td>AFLATOXIN</td>
              <td>20PPB MAX</td>
              <td>20PPB MAX</td>
            </tr>
            <tr>
              <td>BROKEN KERNALS</td>
              <td>3% MAX</td>
              <td>3% MAX</td>
            </tr>
            <tr>
              <td>IMPURITIES</td>
              <td>2% MAX</td>
              <td>2% MAX</td>
            </tr>
            <tr>
              <td>GRAIN ADMIXTURE</td>
              <td>2% MAX</td>
              <td>2% MAX</td>
            </tr>
            <tr>
              <td>PACKING</td>
              <td colSpan={2}>25kg,40kg,50kg bag in PP Bag</td>
            </tr>
            <tr>
              <td>LOADING</td>
              <td colSpan={2}>24 MT IN 20 FT</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default YellowMaize;
