import React from 'react'

const DurumWheatYellowSemolina = () => {
  return (
    <div>
      <table className="responsive-table">
          <thead>
            <tr>
              <th>TYPE</th>
              <th>COARSE SEMOLINA</th>
              <th>FINE SEMOLINA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MOISTURE</td>
              <td>13% MAX</td>
              <td>13% MAX</td>

            </tr>
            <tr>
              <td>PROTIEN</td>
              <td>12% MIN</td>
              <td>12% MIN</td>

            </tr>
            <tr>
              <td>COLOR</td>
              <td>Golden Yellow</td>
              <td>Golden Yellow</td>

            </tr>
            <tr>
              <td>ASH</td>
              <td>0.60-0.95% Max</td>
              <td>0.60-0.95% Max</td>

            </tr>
            <tr>
              <td>CARBOHYDRATES</td>
              <td>375.30gms</td>
              <td>75.30gms</td>

            </tr>
            <tr>
              <td>FIBER</td>
              <td>5.9gms</td>
              <td>5.9gms</td>

            </tr>
            <tr>
              <td>WET GLUTEN</td>
              <td>30% MIN</td>
              <td>30% MIN</td>

            </tr>
            <tr>
              <td>DRY GLUTEN</td>
              <td>9.5% MIN</td>
              <td>9.5% MIN</td>

            </tr>
            <tr>
              <td>BLACK SPECS</td>
              <td>5 PPM Max</td>
              <td>5 PPM Max</td>

            </tr>
            <tr>
              <td>BROWN SPECS</td>
              <td>20 PPM Max</td>
              <td>20 PPM Max</td>

            </tr>
            <tr>
              <td>BROWN SPECS</td>
              <td>20 PPM Max</td>
              <td>20 PPM Max</td>

            </tr><tr>
              <td rowSpan={2}>SIEVE ANALYSIS</td>
              <td>{"<"} 500 – 10 %</td>
              <td>{"<"} 300 – 80%</td>

            </tr>
            <tr>
              <td>{">"} 500 – 90%</td>
              <td>{">"} 300 – 20%</td>

            </tr>
            <tr>
              <td>PACKING</td>
              <td colSpan={2}>Multi wall plain new PP bags of 15 /25 /50 Kgs</td>
            </tr>
            <tr>
              <td>LOADING</td>
              <td colSpan={2}>23.5 MT per 20 FT</td>
            </tr>
          </tbody>
        </table>
    </div>
  )
}

export default DurumWheatYellowSemolina