import React from 'react'

const CastorSeedMeal = () => {
  return (
    <div>
        <table className="responsive-table">
        <thead>
          <tr>
            <th>CASTOR SEED MEAL</th>
            <th>SPECS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MOISTURE</td>
            <td>39% to 12%</td>
          </tr>
          <tr>
            <td>NITROGEN</td>
            <td>4% Min</td>
          </tr>
          <tr>
            <td>OIL</td>
            <td>0.5 to 1% Max</td>
          </tr>
          <tr>
            <td>POTASSIUM</td>
            <td>1% Min</td>
          </tr>
          <tr>
            <td>PHOSPHORUS</td>
            <td>2% Min</td>
          </tr>
          <tr>
            <td>USE</td>
            <td>Cattle,Poultry</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td>25kg, 50kg PP Bags</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default CastorSeedMeal