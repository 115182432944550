import React from "react";
import c1 from "../../images/crousel1.jpg";
import hhm from '../../images/hmm4.jpg';
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import ParallaxImage from "./ParallaxImage";

const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="h-[800px] w-full relative">
        {/* <img
          src={hhm}
          alt="1"
          className="h-[800px] w-full object-cover object-center "
          loading="eager"
        /> */}
        <ParallaxImage src={hhm}/>
      </div>
    </div>
  );
};

export default Header;
