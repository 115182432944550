import React from "react";

const YellowMustardPowder = () => {
  return (
    <div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>MUSTARD POWDER</th>
            <th>SPECS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MOISTURE</td>
            <td>9% Max</td>
          </tr>
          <tr>
            <td>ASH</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>PARTICLE SIZE</td>
            <td>80-100 Mesh</td>
          </tr>
          <tr>
            <td>ADMIXTURE</td>
            <td>0.5% Max</td>
          </tr>
          <tr>
            <td>COLOR</td>
            <td>Light Yellow</td>
          </tr>
          <tr>
            <td>SHELF LIFE</td>
            <td>2 Years if stored Properly</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td>25 Kg Pp Bag Or As Per Buyer Requirements</td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td>13 MT in 20 FT. And 26 MT in 40 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default YellowMustardPowder;
