import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "./drumstick.css";

const Drumstick = () => {
  return (
    <div>
      <div className="container">
        {/* <h2>Product Details</h2> */}
        <div className="table1">
          <div className="row">
            <div className="cell header">Nutrients</div>
            <div className="cell">
            Calories - 64 calories
              <br />
              Dietary Fiber - 2 g
              <br />
              Carbohydrate 8.3 g
            </div>
          </div>
          <div className="row">
            <div className="cell header">Protein</div>
            <div className="cell">9.4g</div>
          </div>
          <div className="row">
            <div className="cell header">Vitamin</div>
            <div className="cell">Vitamin C, 
              Vitamin K, 
              Vitamin B12, 
              Vitamin A, 
              Vitamin B
              </div>
          </div>
          <div className="row">
            <div className="cell header">Minerals</div>
            <div className="cell">
              Riboflavin
              <br />
              Niacin
              <br />
              Iron
              <br />
              Magnesium
              <br />
              calcium
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drumstick;
