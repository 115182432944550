import {
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

const WhyUsCard = ({ cardTitle, desc, image }) => {
  return (
    <div className="flex">
      <Card
        direction={{ base: "column", sm: "row" }}
        overflow="hidden"
        variant="outline"
      >
        <Image
          objectFit="contain"
          maxW={{ base: "150px", sm: "150px" }}
          src={image}
          alt="Caffe Latte"
          loading="lazy"
        />

        <Stack>
          <CardBody>
            <Heading size="md" className="text-left">{cardTitle}</Heading>

            <Text py="2">{desc}</Text>
          </CardBody>
        </Stack>
      </Card>
    </div>
  );
};

export default WhyUsCard;
