import React from 'react'
import "./drumstick.css";

const Peas = () => {
  return (
    <div>
      <div className="container">

        <div className="table1">
          <div className="row">
            <div className="cell header">Nutrients</div>
            <div className="cell">
              Calories - 62 calories / 100grams
              <br />
              Carbs - 11 grams
              <br />
              Fiber - 4 grams
            </div>
          </div>
          <div className="row">
            <div className="cell header">Protein</div>
            <div className="cell">4 grams</div>
          </div>
          <div className="row">
            <div className="cell header">Fat</div>
            <div className="cell">0.4g/100 grams</div>
          </div>
          <div className="row">
            <div className="cell header">Vitamin</div>
            <div className="cell">Vitamin A, Vitamin C, Vitamin K</div>
          </div>
          <div className="row">
            <div className="cell header">Minerals</div>
            <div className="cell">
              Protein
              <br />
              Riboflavin
              <br />
              Niacin
              <br />
              Vitamin B6
              <br />
              Folate
              <br />
              Magnesium
              <br />
              Phosphorus
              <br />
              Copper
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Peas