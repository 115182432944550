import React from 'react';

const NonBasmatiice = () => {
  return (
    <div className='flex flex-col lg:flex-row gap-10'>
        <table className="responsive-table">
        <thead>
          <tr>
            <th>RICE</th>
            <th>IR 64 Parboiled</th>
            <th>IR 64 Raw/White</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Length</td>
            <td>5.8-6.00 mm</td>
            <td>5.8-6.00 mm</td>
          </tr>
          <tr>
            <td>Moisture</td>
            <td>14% MAX</td>
            <td>14% MAX</td>
          </tr>
          <tr>
            <td>Damage & Discoloured</td>
            <td>0.5% MAX</td>
            <td>2.00% MAX</td>
          </tr>
          <tr>
            <td>Broken(2/3 Basis)</td>
            <td>5% MAX</td>
            <td>5% MAX</td>
          </tr>
          <tr>
            <td>Purity Of Rice</td>
            <td>92% min</td>
            <td>92% min</td>
          </tr>
          <tr>
            <td>Quality</td>
            <td colSpan={2}>100% Sortex and Well Milled</td>
          </tr>
          <tr>
            <td>Packing</td>
            <td colSpan={2}>
              5kg,10kg, 25kg,40kg,50kg bag in PP Bag, Jute Bag, Non Woven Bag.
            </td>
          </tr>
          <tr>
            <td>Container Stuffing</td>
            <td colSpan={2}>26 MT per 20 FT</td>
          </tr>
        </tbody>
      </table>

      <table className="responsive-table">
        <thead>
          <tr>
            <th>RICE</th>
            <th>IR 8 Parboiled</th>
            <th>IR 8 Raw/White</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Length</td>
            <td>Round Short</td>
            <td>Round Short</td>
          </tr>
          <tr>
            <td>Moisture</td>
            <td>14% MAX</td>
            <td>14% MAX</td>
          </tr>
          <tr>
            <td>Damage & Discoloured</td>
            <td>0.5% MAX</td>
            <td>2.00% MAX</td>
          </tr>
          <tr>
            <td>Broken(2/3 Basis)</td>
            <td>5% MAX</td>
            <td>5% MAX</td>
          </tr>
          <tr>
            <td>Purity Of Rice</td>
            <td>92% min</td>
            <td>92% min</td>
          </tr>
          <tr>
            <td>Quality</td>
            <td colSpan={2}>100% Sortex and Well Milled</td>
          </tr>
          <tr>
            <td>Packing</td>
            <td colSpan={2}>
              5kg,10kg, 25kg,40kg,50kg bag in PP Bag, Jute Bag, Non Woven Bag.
            </td>
          </tr>
          <tr>
            <td>Container Stuffing</td>
            <td colSpan={2}>26 MT per 20 FT</td>
          </tr>
        </tbody>
      </table>

      <table className="responsive-table">
        <thead>
          <tr>
            <th>RICE</th>
            <th>Sona Masoori</th>
            <th>Swarna Rice</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Length</td>
            <td>5-5.2 mm</td>
            <td>5.2 mm</td>
          </tr>
          <tr>
            <td>Moisture</td>
            <td>14% MAX</td>
            <td>14% MAX</td>
          </tr>
          <tr>
            <td>Damage & Discoloured</td>
            <td>2.00% MAX</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Broken(2/3 Basis)</td>
            <td>5% MAX</td>
            <td>5% MAX</td>
          </tr>
          <tr>
            <td>Purity Of Rice</td>
            <td>92% min</td>
            <td>92% min</td>
          </tr>
          <tr>
            <td>Quality</td>
            <td colSpan={2}>100% Sortex and Well Milled</td>
          </tr>
          <tr>
            <td>Packing</td>
            <td colSpan={2}>
              5kg,10kg, 25kg,40kg,50kg bag in PP Bag, Jute Bag, Non Woven Bag.
            </td>
          </tr>
          <tr>
            <td>Container Stuffing</td>
            <td colSpan={2}>26 MT per 20 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default NonBasmatiice;