import React from 'react'

const TurmericPoowder = () => {
  return (
    <div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>TURMERIC POWDER</th>
            <th>SPECS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MOISTURE</td>
            <td>7% Max</td>
          </tr>
          <tr>
            <td>CURCUMIN CONTENT</td>
            <td>3-5% Max</td>
          </tr>
          <tr>
            <td>INSOLUABLE ASH</td>
            <td>1% Max</td>
          </tr>
          <tr>
            <td>ASH</td>
            <td>6.5% Max</td>
          </tr>
          <tr>
            <td>CHROMATE TEST</td>
            <td>Negative</td>
          </tr>
          <tr>
            <td>PARTICLE SIZE</td>
            <td>100% Pass Through 80 Mesh</td>
          </tr>
          <tr>
            <td>SHELF LIFE</td>
            <td>2 Years if stored Properly</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td>25 kg or 50 kg HDPE or Paper bag</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default TurmericPoowder