import React from "react";

const CorianderPowder = () => {
  return (
    <div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>CORIANDER POWDER</th>
            <th>SPECS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MOISTURE</td>
            <td>10% Max</td>
          </tr>
          <tr>
            <td>TOTAL ASH</td>
            <td>9% Max</td>
          </tr>
          <tr>
            <td>ACID INSOLUABLE ASH</td>
            <td>1.5%</td>
          </tr>
          <tr>
            <td>VOLATILE OIL</td>
            <td>0.3% to 1%</td>
          </tr>
          <tr>
            <td>FOREIGN MATTER</td>
            <td>0.50%</td>
          </tr>
          <tr>
            <td>HEAVY METAL AS LEAD</td>
            <td>2.5 PPB</td>
          </tr>
          <tr>
            <td>ALFATOXIN</td>
            <td>5 PPB</td>
          </tr>
          <tr>
            <td>PROTEIN</td>
            <td>15% Max</td>
          </tr>
          <tr>
            <td>SHELF LIFE</td>
            <td>2 Years if stored Properly</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td>10kg, 20kg,50kg PP bag or Paper Bag</td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td>13 MT in 20 FT. And 26 MT in 40 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CorianderPowder;
