import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdKeyboardArrowRight } from "react-icons/md";
import saltImage from '../images/salt.jpg';
import SaltTable from '../components/productDetails/tables/salt/SaltTable';
import { useNavigate } from 'react-router-dom';


const SaltPage = () => {
    const navigate = useNavigate();

  return (
    <div>
      <div className=" w-full h-[80px]"></div>

      <div className=" w-full h-[60px] bg-richblack-800 text-richblack-25">
        <p className=" md:w-8/12 w-10/12 mx-auto font-inter flex items-center h-full">
          <Breadcrumb
            spacing="8px"
            separator={<MdKeyboardArrowRight color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink onClick={() => navigate("/")}>
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink>Products</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>Salt</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </p>
      </div>

      <div className=" md:w-8/12 w-11/12 mx-auto my-20">
        <div className=" flex lg:flex-row flex-col gap-10 justify-between items-center w-full mb-20">
          <div>
            <img
              src={saltImage}
              alt={"salt"}
              className=" lg:w-[800px] rounded-lg aspect-video object-coover"
            />
          </div>
          <div className=" md:w-[100%] font-inter text-lg text-richblack-800 text-justify">
            <div className=" text-3xl font-inter font-bold mb-8 text-left">
              Salt
            </div>
            Triple Refined Free Flow Iodized Salt & High Purity Table Salt are produced from carefully harvested Sea Salt with our state associate production facility which is registered under ISI (by Indian Standard Bureau) situated near Kandla Port in Gujarat State, India. Standardization and Quality is being consistently maintained by our Professional Quality Control Staff.<br/>
            In addition to this, we also offer Iodized powder salt that is commonly called table salt. It is to be used in homes and restaurants as an additive in various types of cooking. We offer only the purest natural form of salt that has been hygienically cleaned and packed in packages of various capacities. Our special packaging helps to retain the flavor and freshness of salt. Addition of iodine has been shown to reduce some types of diseases. Iodized Powder Salt is known for its wide range of health benefits and its pristine quality.
          </div>
        </div>


        <SaltTable/>
      </div>
    </div>
  );
};

export default SaltPage;