import React from "react";

const CorianderSeed = () => {
  return (
    <div className="flex lg:flex-row flex-col gap-10">
      <table className="responsive-table">
        <thead>
          <tr>
            <th>CORIANDER</th>
            <th>BROWN</th>
            <th>YELLOW</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MOISTURE</td>
            <td>8%</td>
            <td>8%</td>

          </tr>
          <tr>
            <td>PURITY</td>
            <td>98%</td>
            <td>98%</td>

          </tr>
          <tr>
            <td>SPLIT SEED</td>
            <td>5%</td>
            <td>5%</td>

          </tr>
          <tr>
            <td>DAMAGED SEED</td>
            <td>2%</td>
            <td>2%</td>

          </tr>
          <tr>
            <td>ASH</td>
            <td>7%</td>
            <td>7%</td>

          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={4}>New Thin Jute Bags Or New Pp Bags In 8/9/10/25/40 Kgs</td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td colSpan={4}>12 MT in 20 FT and 24 MT in 40 FT</td>
          </tr>
        </tbody>
      </table>

      <table className="responsive-table">
        <thead>
          <tr>
            <th>CORIANDER</th>

            <th>GREEN YELLOW</th>
            <th>BROWN GREEN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MOISTURE</td>

            <td>8%</td>
            <td>8%</td>
          </tr>
          <tr>
            <td>PURITY</td>

            <td>98%</td>
            <td>98%</td>
          </tr>
          <tr>
            <td>SPLIT SEED</td>

            <td>5%</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>DAMAGED SEED</td>

            <td>2%</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>ASH</td>

            <td>7%</td>
            <td>7%</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={4}>New Thin Jute Bags Or New Pp Bags In 8/9/10/25/40 Kgs</td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td colSpan={4}>12 MT in 20 FT and 24 MT in 40 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CorianderSeed;
