import React from "react";

const Peanut = () => {
  return (
    <div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>PEANUTS</th>
            <th>BOLD</th>
            <th>JAVA</th>
            <th>OTHER</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MOISTURE</td>
            <td>5%</td>
            <td>5%</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>ADMIXTURE</td>
            <td>1%</td>
            <td>1%</td>
            <td>1%</td>
          </tr>
          <tr>
            <td>BROKEN & DEFECTIVE</td>
            <td>1% MAX</td>
            <td>1% MAX</td>
            <td>1% MAX</td>
          </tr>
          <tr>
            <td>IMPERFECT GRAINING</td>
            <td>1%</td>
            <td>1%</td>
            <td>1%</td>
          </tr>
          <tr>
            <td>COUNT</td>
            <td>38/42, 40/45, 45/55, 55/60, 70/80, 80/90</td>
            <td>
              40/50, 50/60, 55/65, 60/70, 70/80, 80/90, 90/100, 100/120, 140/160
            </td>
            <td>38/42, 40/45, 45/55</td>
          </tr>
          <tr>
            <td>AFLATOXIN</td>
            <td>5ppb Max</td>
            <td>5ppb Max</td>
            <td>5ppb Max</td>
          </tr>
          <tr>
            <td>INSPECTION</td>
            <td colSpan={3}>QSS, SGS Or As Per Buyer Required</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={3}>In Strong Jute Bags &Vaccum Pack</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Peanut;
