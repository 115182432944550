import React, { useEffect } from "react";
import containerImage from "../images/contact/about-header.jpg";
import WhatWeDo from "../components/home/WhatWeDo";
import WhatWeExport from "../components/home/WhatWeExport";
import Header from "../components/home/Header";
import { Fade, Slide } from "react-awesome-reveal";
import Certificates from "../components/home/Certificates";
import '../components/home/parallax.css'
import Quotation from "../components/home/Quotation";

const Home = () => {
    useEffect(() => {
      const handleScroll = () => {
        const parallaxElements = document.querySelectorAll('.parallax');
        parallaxElements.forEach((element) => {
          const speed = element.getAttribute('data-speed') || 0.5;
          const yPos = -(window.scrollY * speed);
          element.style.transform = `translate3d(0px, ${yPos}px, 0px)`;
        });
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  return (
    <div className="bg-white">
        <div>
          <div className="">
            {/* <Carousel autoplay infinite autoplaySpeed={4000} effect='fade' speed={1000}>
                <div className='h-[800px] w-full relative'>
                    <img src={c1} alt='1' className='h-[800px] w-full object-cover object-center '/>
                    <div className=' absolute z-10 w-full h-full bg-black bg-opacity-60 top-0 left-0'></div>
                    <div className=' absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] z-20 text-[28px] sm:text-[35px] lg:text-[50px] font-inter font-bold text-white text-center sm:leading-7 leading-6 lg:leading-[40px] w-full px-2'> <Fade>WELCOME TO <br/> JAY SOMNATH TRADERS</Fade></div>
                </div>

                <div className='h-[800px] w-full relative'>
                    <img src={c3} alt='1' className='h-[800px] w-full object-cover object-center '/>
                    <div className=' absolute z-10 w-full h-full bg-black bg-opacity-70 top-0 left-0'></div>
                    <div className=' absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] z-20 text-[30px] sm:text-[35px] lg:text-[50px] font-inter font-bold text-white text-center w-full px-3'> End-to-End Logistics Support</div>
                </div>

                <div className='h-[800px] w-full relative'>
                    <img src={c4} alt='1' className='h-[800px] w-full object-cover object-center '/>
                    <div className=' absolute z-10 w-full h-full bg-black bg-opacity-70 top-0 left-0'></div>
                    <div className=' absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] z-20 text-[30px] sm:text-[35px] lg:text-[50px] font-inter font-bold text-white text-center w-full'> Quality Assurance</div>
                </div>

                <div className='h-[800px] w-full relative'>
                    <img src={c2} alt='1' className='h-[800px] w-full object-cover object-center '/>
                    <div className=' absolute z-10 w-full h-full bg-black bg-opacity-70 top-0 left-0'></div>
                    <div className=' absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] z-20 text-[30px] sm:text-[35px] lg:text-[50px] font-inter font-bold text-white text-center w-full'> World Class Products</div>
                </div>
                
            </Carousel> */}
          </div>

          <Header />
          <div className="lg:w-8/12 mx-auto w-10/12 ">
            {/* ABOUT US */}
            <Fade delay={200} duration={1000} triggerOnce={true} fraction={0.3}>
            <div className="w-full block mb-20 mt-12 md:flex flex-row justify-between items-center gap-16 font-inter ">

                <main className=" mb-10 md:mb-0  w-full">
                  <div className=" text-5xl font-bold text-center mb-10">
                    Our History
                  </div>
                  <div className=" text-lg text-justify">
                    Company was founded in 2017 located at Ahmedabad western part
                    of INDIA. It was founded by Mr. Mahendra Singh Rathore by
                    interest to expand business globally. We are dealing in Fresh
                    Vegetables and Fresh Fruits , Handicrafts, Garments, Ceramic &
                    Stone & Home Decorative. <br /><br/>
                    In the starting phase of a company doing export on a small
                    scale to provide quality products, cost effectively on timely
                    delivery with complete client satisfaction. After that we
                    expanded our business on a large scale to provide better
                    quality products by helping work to daily basis workers work
                    on handicrafts & handmade products
                  </div>
                </main>
                {/* <div className=" w-full flex justify-center items-center">
                  <img src={containerImage} alt="logo" />
                </div> */}
            </div>
            </Fade>
          </div>

          <WhatWeDo />
          <WhatWeExport />
          {/* <div className=" bg-formImage bg-no-repeat bg-cover bg-fixed relative">
            <div className=" absolute w-full h-full bg-black opacity-50 z-0"></div>
            <div className=" relative z-10"><Quotation/></div>
          </div> */}
          <div className=" bg-richblack-800" id="section1">
            <Quotation/>
          </div>
          <Certificates/>
        </div>
    </div>
  );
};

export default Home;
