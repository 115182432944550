import React, { useEffect, useState } from "react";
import { Button, Dropdown, Drawer } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { IoMenu } from "react-icons/io5";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import logo from "../images/logo/logo.jpg";

const Navbar = () => {
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            setActiveBar("product");
            navigate("/products/fruits");
          }}
          className=" text-base w-[180px] font-inter mt-4 ml-2 hover:text-yellow-400"
        >
          Fruits
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setActiveBar("product");
            navigate("/products/vegetables");
          }}
          className=" text-base w-[120px] font-inter ml-2 hover:text-yellow-400"
        >
          Vegetables
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() => {
            setActiveBar("product");
            navigate("/products/grains");
          }}
          className=" text-base w-[120px] font-inter ml-2 hover:text-yellow-400"
        >
          Grains
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          onClick={() => {
            setActiveBar("product");
            navigate("/products/flourProducts");
          }}
          className=" text-base w-[120px] font-inter ml-2 hover:text-yellow-400"
        >
          Flour products
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          onClick={() => {
            setActiveBar("product");
            navigate("/products/rice");
          }}
          className=" text-base w-[120px] font-inter ml-2 hover:text-yellow-400"
        >
          Rice
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div
          onClick={() => {
            setActiveBar("product");
            navigate("/products/oilSeed");
          }}
          className=" text-base w-[120px] font-inter ml-2 hover:text-yellow-400"
        >
          Oil seed
        </div>
      ),
    },
    {
      key: "7",
      label: (
        <div
          onClick={() => {
            setActiveBar("product");
            navigate("/product/salt");
          }}
          className=" text-base w-[120px] font-inter ml-2 hover:text-yellow-400"
        >
          Salt
        </div>
      ),
    },
    {
      key: "8",
      label: (
        <div
          onClick={() => {
            setActiveBar("product");
            navigate("/products/cattleFeed");
          }}
          className=" text-base w-[120px] font-inter ml-2 hover:text-yellow-400"
        >
          Cattle Feed
        </div>
      ),
    },
    {
      key: "9",
      label: (
        <div
          onClick={() => {
            setActiveBar("product");
            navigate("/products/groundSpices");
          }}
          className=" text-base w-[120px] font-inter ml-2 hover:text-yellow-400"
        >
          Ground Spices
        </div>
      ),
    },
    {
      key: "10",
      label: (
        <div
          onClick={() => {
            setActiveBar("product");
            navigate("/products/wholeSpices");
          }}
          className=" text-base w-[120px] font-inter ml-2 hover:text-yellow-400"
        >
          Whole spices
        </div>
      ),
    },
    {
      key: "11",
      label: (
        <div
          onClick={() => {
            setActiveBar("product");
            navigate("/product/coconut");
          }}
          className=" text-base w-[120px] font-inter mb-4 ml-2 hover:text-yellow-400"
        >
          Coconut
        </div>
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  const [activeBar, setActiveBar] = useState("home");

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [navBg, setNavBg] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80 && ['/', '/contact-us', '/about-us'].includes(location.pathname)) {
        setNavBg(true);
      } else {
        setNavBg(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes("products")) {
      setActiveBar("product");
    } else if (location.pathname.includes("contact-us")) {
      setActiveBar("contact");
    } else if (location.pathname.includes("about-us")) {
      setActiveBar("about");
    } else if (location.pathname.includes("certificates")) {
      setActiveBar("certificates");
    } else {
      setActiveBar("home");
    }
  }, [location.pathname]);

  // bg-white
  //   shadow-[rgba(13,_38,_76,_0.40)_0px_1px_10px]
  return (
    <div className=" relative">
      <div
        className={`w-[100%] h-[80px] 
      ${navBg || !['/', '/contact-us', '/about-us'].includes(location.pathname) ? "bg-richblack-900" : ""}
     fixed top-0 z-30  font-inter font-medium transition-all `}
      >
        <div className=" md:w-8/12 w-11/12 mx-auto h-full flex justify-between items-center">
          {/* <div className=" text-white font-inter text-2xl font-bold">JST</div> */}
          <img
            src={logo}
            alt="logo"
            className=" w-[52px] rounded-[50%] cursor-pointer"
            onClick={() => navigate("/")}
          />

          <div className=" hidden gap-10 lg:flex justify-between items-center text-white">
            <div
              onClick={() => {
                setActiveBar("home");
              }}
              className={`cursor-pointer ${
                activeBar === "home" ? " text-yellow-400" : "text-white"
              }`}
            >
              <Link to={"/"}>HOME</Link>
            </div>

            <div
              className={`cursor-pointer ${
                activeBar === "product" ? " text-yellow-400" : "text-white"
              }`}
            >
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomLeft"
              >
                <div className="flex justify-center items-center gap-2">
                  <div>PRODUCTS</div>
                  <div>
                    <IoIosArrowDown />
                  </div>
                </div>
              </Dropdown>
            </div>

            <div
              onClick={() => {
                setActiveBar("about");
              }}
              className={`cursor-pointer ${
                activeBar === "about" ? " text-yellow-400" : "text-white"
              }`}
            >
              <Link to={"/about-us"}>ABOUT</Link>
            </div>
            <div
              onClick={() => {
                setActiveBar("certificates");
              }}
              className={`cursor-pointer ${
                activeBar === "certificates" ? " text-yellow-400" : "text-wihte"
              }`}
            >
              <Link to={"/certificates"}>CERTIFICATES</Link>
            </div>
            <div
              onClick={() => {
                setActiveBar("contact");
              }}
              className={`cursor-pointer ${
                activeBar === "contact" ? " text-yellow-400" : "text-wite"
              }`}
            >
              <Link to={"/contact-us"}>CONTACT US</Link>
            </div>
          </div>

          <div className=" block lg:hidden">
            <div onClick={showDrawer}>
              <IoMenu className=" text-[45px] text-white" />
            </div>
            <Drawer title="" onClose={onClose} open={open}>
              <div className="flex flex-col gap-y-2 font-inter text-base pl-2">
                <Link to={"/"} onClick={onClose}>
                  HOME
                </Link>
                {/* <p className=" w-full h-[1px] bg-black bg-opacity-40"></p> */}
                <Accordion allowToggle>
                  <AccordionItem>
                    <AccordionButton className=" -ml-4">
                      PRODUCTS
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <div className=" flex flex-col gap-y-2 text-base font-inter">
                        <Link
                          to={"/products/fruits"}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Fruits
                        </Link>
                        <Link
                          to={"/products/vegetables"}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Vegetables
                        </Link>
                        <Link
                          to={"/products/grains"}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Grains
                        </Link>
                        <Link
                          to={"/products/flourProducts"}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Flour products
                        </Link>
                        <Link
                          to={"/products/rice"}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Rice
                        </Link>
                        <Link
                          to={"/products/oilSeed"}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Oil seed
                        </Link>
                        <Link
                          to={"/product/salt"}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Salt
                        </Link>
                        <Link
                          to={"/products/cattleFeed"}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Cattle feed
                        </Link>
                        <Link
                          to={"/products/groundSpices"}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Ground Spices
                        </Link>
                        <Link
                          to={"/products/wholeSpices"}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Whole spices
                        </Link>
                        <Link
                          to={"/product/coconut"}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Semi Husked Coconut
                        </Link>
                      </div>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                {/* <p className=" w-full h-[1px] bg-black bg-opacity-40"></p> */}
                <Link to={"/about-us"} onClick={onClose}>
                  ABOUT
                </Link>
                <p className=" w-full h-[1px] bg-black bg-opacity-10"></p>
                <Link to={"/certificates"} onClick={onClose}>CERTIFICATES</Link>
                <p className=" w-full h-[1px] bg-black bg-opacity-10"></p>
                <Link to={"/contact-us"} onClick={onClose}>
                  CONTACT US
                </Link>
                <p className=" w-full h-[1px] bg-black bg-opacity-10"></p>
              </div>
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
