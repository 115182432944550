import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'
import ScrollToTop from './utils/ScrollToTop';
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider>
    <BrowserRouter>
     <ScrollToTop/>
      <App/>
      <Toaster/>
    </BrowserRouter>
  </ChakraProvider>
);