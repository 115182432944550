import React from "react";

const SoyabeanMeal = () => {
  return (
    <div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>SOYABEAN MEAL</th>
            <th>LOW PRO</th>
            <th>MEDIUM PRO</th>
            <th>HIGGH PRO</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>PROTEIN</td>
            <td>46-48%</td>
            <td>40-42%</td>
            <td>50-52%</td>
          </tr>
          <tr>
            <td>CRUDE FIBER</td>
            <td>6% Max</td>
            <td>6% </td>
            <td>6% </td>
          </tr>
          <tr>
            <td>FAT</td>
            <td>1%</td>
            <td>1%</td>
            <td>1%</td>
          </tr>
          <tr>
            <td>MOISTURE</td>
            <td>12% Max</td>
            <td>13%</td>
            <td>12%</td>
          </tr>
          <tr>
            <td>OIL/TOTAL FAT</td>
            <td>1.5% Max</td>
            <td>1.5%</td>
            <td>1.5%</td>
          </tr>
          <tr>
            <td>SAND/SILICA</td>
            <td>2% Max</td>
            <td>2.55% Max</td>
            <td>2% Max</td>
          </tr>
          <tr>
            <td>UREAS ACT.</td>
            <td colSpan={3}>
              0.05 Min – 0.30 Max MG/N2/GM/Min AT 30 DEG Celsius (By ECC Method)
            </td>
          </tr>
          <tr>
            <td>AFLATOXIN</td>
            <td colSpan={3}>50 PPB Max</td>
          </tr>
          <tr>
            <td>INSPECTION</td>
            <td colSpan={3}>50 Kg. PP Bag OR As per buyer requirement</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={3}>20FT Container 21MT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SoyabeanMeal;
