import React from "react";

const FennelSeed = () => {
  return (
    <div className="flex lg:flex-row flex-col gap-10">
      <table className="responsive-table">
        <thead>
          <tr>
            <th>FENNEL SEEDS</th>
            <th>FENNEL</th>
            <th>FENNEL AND ANISEED</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <td>COLOR</td>
            <td>GREENISH</td>
            <td>GREENISH</td>

          </tr>
          <tr>
            <td>PURITY</td>
            <td>99%</td>
            <td>98%</td>

          </tr>
          <tr>
            <td>PLACE OF ORIGIN</td>
            <td>INDIA</td>
            <td>INDIA</td>

          </tr>
          <tr>
            <td>STYLE</td>
            <td>DRIED</td>
            <td>DRIED</td>

          </tr>
          <tr>
            <td>MOISTURE</td>
            <td>10%</td>
            <td>10%</td>

          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={2}>25 kg ,50 kg HDPE or Paper Bag or Box Packing</td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td colSpan={2}>12 MT in 20 FT and 24 MT in 40 FT</td>
          </tr>
        </tbody>
      </table>

      <table className="responsive-table">
        <thead>
          <tr>
            <th>FENNEL SEEDS</th>

            <th>FENNEL</th>
            <th>FENNEL AND ANISEED</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>COLOR</td>

            <td>BROWN</td>
            <td>BROWN</td>
          </tr>
          <tr>
            <td>PURITY</td>

            <td>97%</td>
            <td>98%</td>
          </tr>
          <tr>
            <td>PLACE OF ORIGIN</td>

            <td>INDIA</td>
            <td>INDIA</td>
          </tr>
          <tr>
            <td>STYLE</td>

            <td>DRIED</td>
            <td>DRIED</td>
          </tr>
          <tr>
            <td>MOISTURE</td>

            <td>10%</td>
            <td>10%</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={2}>25 kg ,50 kg HDPE or Paper Bag or Box Packing</td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td colSpan={2}>12 MT in 20 FT and 24 MT in 40 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FennelSeed;
