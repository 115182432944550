import React from 'react';

const SteamBasmatiRice = () => {
  return (
    <div className='flex flex-col lg:flex-row gap-10'>
        <table className="responsive-table">
        <thead>
          <tr>
            <th>RICE</th>
            <th>1121 Steam Basmati</th>
            <th>1509 Steam Basmati</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Length</td>
            <td>8.3 MM</td>
            <td>8.3 MM</td>
          </tr>
          <tr>
            <td>Moisture</td>
            <td>14% MAX</td>
            <td>14% MAX</td>
          </tr>
          <tr>
            <td>Damage & Discoloured</td>
            <td>0.5% MAX</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Broken</td>
            <td>0.5% MAX</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Purity of Rice</td>
            <td>92%</td>
            <td>92%</td>
          </tr>
          <tr>
            <td>Packing</td>
            <td colSpan={2}>25kg,10kg, 25kg,40kg,50kg bag in PP Bag, Jute Bag, Non Woven Bag Or As Per Buyer’s Requirement.</td>
          </tr>
          <tr>
            <td>Container Stuffing</td>
            <td colSpan={2}>24 MT in 20 FT</td>
          </tr>
        </tbody>
      </table>

      <table className="responsive-table">
        <thead>
          <tr>
            <th>RICE</th>
            <th>Sugandha Steam Basmati</th>
            <th>Pusa Steam Basmati</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Length</td>
            <td>7.5 MM</td>
            <td>7.5 MM</td>
          </tr>
          <tr>
            <td>Moisture</td>
            <td>14% MAX</td>
            <td>14% MAX</td>
          </tr>
          <tr>
            <td>Damage & Discoloured</td>
            <td>0.5% MAX</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Broken</td>
            <td>0.5% MAX</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Purity of Rice</td>
            <td>92%</td>
            <td>92%</td>
          </tr>
          <tr>
            <td>Packing</td>
            <td colSpan={2}>25kg,10kg, 25kg,40kg,50kg bag in PP Bag, Jute Bag, Non Woven Bag Or As Per Buyer’s Requirement.</td>
          </tr>
          <tr>
            <td>Container Stuffing</td>
            <td colSpan={2}>24 MT in 20 FT</td>
          </tr>
        </tbody>
      </table>

      <table className="responsive-table">
        <thead>
          <tr>
            <th>RICE</th>
            <th>Sharbati Steam Basmati</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Length</td>
            <td>7-7.20 MM</td>
          </tr>
          <tr>
            <td>Moisture</td>
            <td>14% MAX</td>
          </tr>
          <tr>
            <td>Damage & Discoloured</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Broken</td>
            <td>0.5% MAX</td>
          </tr>
          <tr>
            <td>Purity of Rice</td>
            <td>92%</td>
          </tr>
          <tr>
            <td>Packing</td>
            <td colSpan={2}>25kg,10kg, 25kg,40kg,50kg bag in PP Bag, Jute Bag, Non Woven Bag Or As Per Buyer’s Requirement.</td>
          </tr>
          <tr>
            <td>Container Stuffing</td>
            <td colSpan={2}>24 MT in 20 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SteamBasmatiRice;