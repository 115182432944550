import React from "react";

const RapeSeedMeal = () => {
  return (
    <div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>RAPE SEED MEAL</th>
            <th>SPECS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MOISTURE</td>
            <td>12%</td>
          </tr>
          <tr>
            <td>PROTEIN</td>
            <td>38% Min</td>
          </tr>
          <tr>
            <td> CRUDE FIBER</td>
            <td>12%</td>
          </tr>
          <tr>
            <td>SAND/SILICA</td>
            <td>2.5%</td>
          </tr>
          <tr>
            <td>FAT</td>
            <td>1.5% Max</td>
          </tr>
          <tr>
            <td>AFLATOCXIN</td>
            <td>20% PPB Max</td>
          </tr>
          <tr>
            <td>OIL CONTENT</td>
            <td>1.5%</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td>25 kg, 50 kg New PP Bag</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RapeSeedMeal;
