import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Image,
  Stack,
  Heading,
  Text,
  ButtonGroup,
  Button,
  Divider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ProductsThumbnail = ({ image, cardTitle, desc, id, category }) => {
  const truncateText = (text, length) => {
    if (text.length <= length) {
      return text;
    }
    return text.slice(0, length) + "...";
  };
  const navigate = useNavigate();
  return (
    <div className=" shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
      <Card maxW="sm">
        <CardBody>
          <Image
            src={image}
            borderRadius="lg"
            aspectRatio={"10/6"}
            objectFit={"cover"}
            loading="lazy"
          />
          <Stack mt="6" spacing="3">
            <Heading size="md">{cardTitle}</Heading>
            <Text className=" text-justify">{truncateText(desc, 120)}</Text>
          </Stack>
          <Button colorScheme="yellow" className="mt-4" onClick={()=>{
            navigate(`/products/${category}/${id}`)
          }}>
            Explore
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default ProductsThumbnail;
