import React from 'react'

const IndianGreenMillet = () => {
  return (
    <div>
      <table className="responsive-table">
          <thead>
            <tr>
              <th >GREEN MILLET</th>
              <th >MACHINE CLEAN</th>
              <th>NON M/C</th>
              <th>ANIMAL FEED</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MOISTURE</td>
              <td>14%</td>
              <td>14%</td>
              <td>14%</td>
            </tr>
            <tr>
              <td>PROTIEN</td>
              <td>9%</td>
              <td>8%</td>
              <td>8%</td>
            </tr>
            <tr>
              <td>FOREIGN MATTER</td>
              <td>1%</td>
              <td>2%</td>
              <td>3%</td>
            </tr>
            <tr>
              <td>BROKEN</td>
              <td>1%</td>
              <td>2%</td>
              <td>3%</td>
            </tr>
            <tr>
              <td>ENERGY</td>
              <td>300K/CAL</td>
              <td>300K/CAL</td>
              <td>300K/CAL</td>
            </tr>
            <tr>
              <td>AFLATOXIN</td>
              <td>20PPB MAX</td>
              <td>20PPB MAX</td>
              <td>20PPB MAX</td>
            </tr>
            <tr>
              <td>SHRUKEN</td>
              <td>2%</td>
              <td>3%</td>
              <td>3%</td>
            </tr>
            <tr>
              <td>PACKING</td>
              <td colSpan={3}>25kg,40kg,50kg bag in PP Bag</td>
            </tr>
            <tr>
              <td>LOADING</td>
              <td colSpan={3}>24 MT in 20 FT. Container</td>
            </tr>
          </tbody>
        </table>
    </div>
  )
}

export default IndianGreenMillet