import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import countryCode from "../../data/countryCode.json";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import "./quotation.css";
import toast from "react-hot-toast";
import emailjs from '@emailjs/browser';

const Quotation = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  const [deliveryTerms, setDeliveryTerms] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [buyerBroker, setBuyerBroker] = useState("");
  const [loading , setLoading] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    // e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm('service_lkkwpkn', 'template_l0fib9t', form.current, {
        publicKey: 'yz8M07mgK3x_jJcl_',
      })
      .then(
        () => {
          toast.success("Quotation sent!");
          console.log('SUCCESS!');
          setLoading(false);
          reset();
          setBuyerBroker("");
          setDeliveryTerms("");
          setPaymentTerms("");
        },
        (error) => {
          toast.error("An error occured");
          console.log('FAILED...', error.text);
          setLoading(false);
        },
      );
  };

  const quotationSubmit = (data) => {
    if (data) {
        const finalData = {
            data,
            deliveryTerms:deliveryTerms,
            paymentTerms:paymentTerms,
            buyerBroker:buyerBroker
        }
      console.log(finalData);
      toast.success("Quotation sent !")
      reset();
      setBuyerBroker("");
      setDeliveryTerms("");
      setPaymentTerms("");
    } else {
      console.log("no data found");
    }
  };

  return (
    <div className="lg:w-8/12 mx-auto w-10/12 text-white py-10">
      <div className="mb-14 lg:w-[75%] w-full mx-auto ">
        <div className="font-inter font-bold text-[38px] text-center mt-14 mb-14">
          Get a Price Quote
        </div>
        <form
          ref={form}
          onSubmit={handleSubmit(sendEmail)}
          className="flex flex-col gap-6 w-full text-white "
        >
          <div className="flex md:gap-6 w-full">
            <div className="w-full mr-4">
              <label className="">
                First Name<sup className=" text-pink-400 font-inter"> *</sup>
                <br />
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  {...register("firstName", { required: true })}
                  placeholder="First name"
                  className="w-full h-[48px] bg-white rounded-lg px-4 shadow-inputShadow my-1 text-black"
                />
                {errors.firstName && <span className="text-red-500 ">Please enter your First name</span>}
              </label>
            </div>

            <div className="w-full">
              <label>
                Last Name<sup className=" text-pink-400 font-inter"> *</sup>
                <br />
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  {...register("lastName", { required: true })}
                  placeholder="Last name"
                  className="w-full h-[48px] bg-white rounded-lg px-4 shadow-inputShadow my-1 text-black"
                />
                {errors.lastName && <span className="text-red-500 ">Please enter your Last name</span>}
              </label>
            </div>
          </div>

          <div className="w-full flex flex-col gap-6 md:flex-row">
            <div className="w-full mr-4">
              <label>
                Email Address<sup className=" text-pink-400 font-inter"> *</sup>
                <br />
                <input
                  type="email"
                  name="email"
                  id="email"
                  {...register("email", { required: true })}
                  placeholder="Email address"
                  className="w-full h-[48px] bg-white rounded-lg px-4 shadow-inputShadow my-1 text-black"
                />
                {errors.email && <span className="text-red-500 ">Please enter your email</span>}
              </label>
            </div>

            <div className="w-full">
              <label className="">
                Required product
                <sup className=" text-pink-400 font-inter"> *</sup>
                <br />
                <input
                  type="text"
                  name="requiredProduct"
                  id="requiredProduct"
                  {...register("requiredProduct", { required: true })}
                  placeholder="Eg. Rice, Wheat"
                  className="w-full h-[48px] bg-white rounded-lg px-4 shadow-inputShadow my-1 text-black"
                />
                {errors.requiredProduct && (
                  <span className="text-red-500 ">Please enter Required product</span>
                )}
              </label>
            </div>
          </div>

          <div className="w-full flex flex-col-reverse gap-6 md:flex-row">
            <div className="w-full mr-4">
              <label className="">
                Delivery Port ( IF: cif,fob,for)
                <sup className=" text-pink-400 font-inter"> *</sup>
                <br />
                <input
                  type="text"
                  name="deliveryPort"
                  id="deliveryPort"
                  {...register("deliveryPort", { required: true })}
                  placeholder="Eg. Mundra port"
                  className="w-full h-[48px] bg-white rounded-lg px-4 shadow-inputShadow my-1 text-black"
                />
                {errors.deliveryPort && <span className="text-red-500 ">Please enter delivery port</span>}
              </label>
            </div>

            <div className="w-full">
              <label className="">
                Total Quantity {"( Metric TON )"}
                <sup className=" text-pink-400 font-inter"> *</sup>
                <br />
                <input
                  type="number"
                  name="totalQunatity"
                  id="totalQunatity"
                  {...register("totalQunatity", { required: true })}
                  placeholder="Eg. 10 "
                  className="w-full h-[48px] bg-white rounded-lg px-4 shadow-inputShadow my-1 text-black"
                />
                {errors.totalQunatity && (
                  <span className="text-red-500 ">Please enter Total Quantity</span>
                )}
              </label>
            </div>
          </div>

          <div className="w-full flex flex-col-reverse gap-6 md:flex-row">
            <div className="flex md:block md:w-full">
              <div className="w-full mr-4">
                <label className="">
                  <p className="mb-2 text-lg">You are ?<sup className=" text-pink-400 font-inter"> *</sup></p>
                  {/* <br /> */}
                  <RadioGroup onChange={setBuyerBroker} value={buyerBroker} name="buyerBroker" id="buyerBroker">
                    <Stack direction="column">
                      <Radio colorScheme="yellow" value="Buyer">
                        Buyer
                      </Radio>
                      <Radio colorScheme="yellow" value="Broker">
                        Broker
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  {/* {errors.firstName && (
                    <span className="text-red-500 ">This field is required</span>
                  )} */}
                </label>
              </div>
              <div className="w-full md:hidden block">
                <label className=" ">
                  <p className="mb-2 text-lg">Delivery Terms
                  <sup className=" text-pink-400 font-inter"> *</sup></p>
                  {/* <br /> */}
                  <RadioGroup onChange={setDeliveryTerms} value={deliveryTerms} name="deliveryTerms" id="deliveryTerms">
                    <Stack direction="column">
                      <Radio colorScheme="yellow" value="CIF">
                        CIF
                      </Radio>
                      <Radio colorScheme="yellow" value="FOB">
                        FOB
                      </Radio>
                      <Radio colorScheme="yellow" value="FOR">
                        FOR
                      </Radio>
                      <Radio colorScheme="yellow" value="Ex-mill">
                        Ex-mill
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  {/* {errors.firstName && (
                    <span className="text-red-500 ">This field is required</span>
                  )} */}
                </label>
              </div>
            </div>

            <div className="w-full md:ml-4">
              <label>
              <p className="mb-1">Contact no
              <sup className=" text-pink-400 font-inter"> *</sup></p>
                {/* <br /> */}
                <div className="flex gap-x-4 items-center">
                  <div>
                    <select
                      name="countryCode"
                      id="countryCode"
                      {...register("countryCode", { required: true })}
                      className="bg-white w-[90px] h-[48px] rounded-lg shadow-inputShadow px-[10px] text-center border-none text-black"
                    >
                      {countryCode.map((element, index) => {
                        return (
                          <option key={index} value={element.code}>
                            {element.code} - {element.country}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <input
                    type="number"
                    name="contactNumber"
                    id="contactNumber"
                    {...register("contactNumber", { required: true })}
                    placeholder="01234 5678"
                    className=" w-[calc(100%-90px)] h-[48px] bg-white rounded-lg px-4 shadow-inputShadow my-1 text-black"
                  />
                </div>
                {errors.contactNo && (
                    <span className="text-red-500 ">Please enter your Contact number</span>
                  )}
              </label>
            </div>
          </div>

          <div className="w-full flex">
            <div className="w-full mr-4">
              <label className=" ">
                <p className="mb-2 text-lg">Payment Terms<sup className=" text-pink-400 font-inter"> *</sup></p>
                {/* <br /> */}
                <RadioGroup onChange={setPaymentTerms} value={paymentTerms} name="paymentTerms" id="paymentTerms">
                  <Stack direction="column">
                    <Radio colorScheme="yellow" value="100% ADVANCE">
                      100% ADVANCE
                    </Radio>
                    <Radio
                      colorScheme="yellow"
                      value="50% ADVANCE + 50% SCAN B/L"
                    >
                      50% ADVANCE + 50% SCAN B/L
                    </Radio>
                    <Radio
                      colorScheme="yellow"
                      value="10% ADVANCE + 90% LC AT SIGHT"
                    >
                      10% ADVANCE + 90% LC AT SIGHT
                    </Radio>
                    <Radio
                      colorScheme="yellow"
                      value="100% LC AT SIGHT (TRANSFERABLE, IRREVOCABLE)"
                    >
                      100% LC AT SIGHT (TRANSFERABLE, IRREVOCABLE)
                    </Radio>
                  </Stack>
                </RadioGroup>
                {/* {errors.firstName && <span className="text-red-500 ">This field is required</span>} */}
              </label>
            </div>

            <div className="w-full hidden md:block">
              <label className=" ">
                <p className="mb-2 text-lg">Delivery Terms
                <sup className=" text-pink-400 font-inter"> *</sup></p>
                {/* <br /> */}
                <RadioGroup onChange={setDeliveryTerms} value={deliveryTerms} name="deliveryTerms" id="deliveryTerms">
                  <Stack direction="column">
                    <Radio colorScheme="yellow" value="CIF">
                      CIF
                    </Radio>
                    <Radio colorScheme="yellow" value="FOB">
                      FOB
                    </Radio>
                    <Radio colorScheme="yellow" value="FOR">
                      FOR
                    </Radio>
                    <Radio colorScheme="yellow" value="Ex-mill">
                      Ex-mill
                    </Radio>
                  </Stack>
                </RadioGroup>
                {/* {errors.firstName && <span className="text-red-500 ">This field is required</span>} */}
              </label>
            </div>
          </div>

          <div className="w-full">
            <label className="mr-4">
              Detailed discription about product and your requirement
              <sup className=" text-pink-400 font-inter"> *</sup>
              <br />
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="7"
                {...register("message", { required: true })}
                placeholder="Type your message here..."
                className="w-full h-[160px] bg-white rounded-lg px-4 shadow-inputShadow py-3 my-1 border-none text-black"
              />
              {errors.message && <span className="text-red-500 ">Please enter your Message</span>}
            </label>
          </div>

          <div className="w-full flex justify-center">
            <button
              type="submit"
              className="w-[190px] text-center mt-4 mb-5 h-[48px] bg-yellow-300 rounded-lg text-richblack-900 font-inter text-lg"
              disabled={loading}
            >
              {loading ? "Sending..." : "Request a Quote"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Quotation;
