import React from 'react'

const CuminSeed = () => {
  return (
    <div className='flex lg:flex-row flex-col gap-10'>
        <table className="responsive-table">
        <thead>
          <tr>
            <th>CUMIN</th>
            <th colSpan={3}>EUROPIAN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>PROCESS</td>
            <td>SORTEX</td>
            <td>M/C</td>
            <td>M/C</td>
          </tr>
          <tr>
            <td>PURITY</td>
            <td>99.5%</td>
            <td>99%</td>
            <td>98%</td>
          </tr>
          <tr className=''>
            <td>VOLATILE OIL CONTENT</td>
            <td>2.5%-<br/>4.5%</td>
            <td>2.5%-<br/>4.5%</td>
            <td>2.5%-<br/>4.5%</td>
          </tr>
          <tr>
            <td>ADMIXTURE</td>
            <td>0.5%</td>
            <td>1%</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>MOISTURE</td>
            <td>7%</td>
            <td>7%</td>
            <td>7%</td>
          </tr>
          <tr>
            <td>CROP</td>
            <td>NEW</td>
            <td>NEW</td>
            <td>NEW</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={3}>25KG, 50KG HDPE or Paper Bag</td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td colSpan={3}>12 MT in 20 FT and 24 MT in 40 MT</td>
          </tr>
        </tbody>
      </table>


      <table className="responsive-table">
        <thead>
          <tr>
            <th>CUMIN</th>
            <th colSpan={2}>GULF</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>PROCESS</td>
            <td>M/C</td>
            <td>M/C</td>
          </tr>
          <tr>
            <td>PURITY</td>
            <td>99%</td>
            <td>98%</td>
          </tr>
          <tr>
            <td>VOLATILE OIL CONTENT</td>
            <td>2.5%-4.5%</td>
            <td>2.5%-4.5%</td>
          </tr>
          <tr>
            <td>ADMIXTURE</td>
            <td>1%</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>MOISTURE</td>
            <td>7%</td>
            <td>7%</td>
          </tr>
          <tr>
            <td>CROP</td>
            <td>NEW</td>
            <td>NEW</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={2}>25KG, 50KG HDPE or Paper Bag</td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td colSpan={2}>12 MT in 20 FT and 24 MT in 40 MT</td>
          </tr>
        </tbody>
      </table>      
      
      <table className="responsive-table">
        <thead>
          <tr>
            <th>CUMIN</th>
            <th colSpan={2}>SINGAPORE, MALAISYA, AFRICA</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>PROCESS</td>
            <td>M/C</td>
            <td>M/C</td>
          </tr>
          <tr>
            <td>PURITY</td>
            <td>99%</td>
            <td>98%</td>
          </tr>
          <tr>
            <td>VOLATILE OIL CONTENT</td>
            <td>2.5%-4.5%</td>
            <td>2.5%-4.5%</td>
          </tr>
          <tr>
            <td>ADMIXTURE</td>
            <td>1%</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>MOISTURE</td>
            <td>7%</td>
            <td>7%</td>
          </tr>
          <tr>
            <td>CROP</td>
            <td>NEW</td>
            <td>NEW</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={2}>25KG, 50KG HDPE or Paper Bag</td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td colSpan={2}>12 MT in 20 FT and 24 MT in 40 MT</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default CuminSeed