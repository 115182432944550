import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { Spinner } from "@chakra-ui/react";
import Products from "./pages/Products";
import ProductDetails from "./pages/ProductDetails";
import SaltPage from "./pages/SaltPage";
import CertificatesPage from "./pages/CertificatesPage";
import CoconutPage from "./pages/CoconutPage";

function App() {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);
  return (
    <div className="App  bg-white overflow-x-hidden overflow-y-auto relative">
      {loading ? (
        <div className="w-[100vw] h-[100vh] flex justify-center items-center">
          <Spinner size={"xl"} />
        </div>
      ) : (
        <div>
          <Navbar />
          <div className="">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/certificates" element={<CertificatesPage />} />
              <Route path="/products/:category" element={<Products />} />
              <Route path="/product/salt" element={<SaltPage />} />
              <Route path="/product/coconut" element={<CoconutPage />} />
              <Route path="/products/:category/:id" element={<ProductDetails />} />
            </Routes>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default App;
