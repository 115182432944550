import React from "react";

const Soyabean = () => {
  return (
    <div>
      <div className="container">

        <div className="table1">
          <div className="row">
            <div className="cell header">SOYABEAN</div>
            <div className="cell">JS 2117</div>
          </div>
          <div className="row">
            <div className="cell header">PURITY</div>
            <div className="cell">99% MIN</div>
          </div>
          <div className="row">
            <div className="cell header">FOREIGN MATTER</div>
            <div className="cell">1% MAX</div>
          </div>
          <div className="row">
            <div className="cell header">MOISTURE</div>
            <div className="cell">10% MAX</div>
          </div>
          <div className="row">
            <div className="cell header">SPLIT</div>
            <div className="cell">2% MAX</div>
          </div>
          <div className="row">
            <div className="cell header">PROTEIN</div>
            <div className="cell">37-42% MAX</div>
          </div>
          <div className="row">
            <div className="cell header">DAMAGES</div>
            <div className="cell">1.5-3% MAX</div>
          </div>
          <div className="row">
            <div className="cell header">OIL CONTENT</div>
            <div className="cell">18-19%</div>
          </div>
          <div className="row">
            <div className="cell header">ORIGIN</div>
            <div className="cell">INDIA</div>
          </div>
          <div className="row">
            <div className="cell header">PACKING</div>
            <div className="cell">PPBAG</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Soyabean;
