import React from 'react'

const MilingDurumWheat = () => {
  return (
    <div>
      <table className="responsive-table">
          <thead>
            <tr>
              <th >WHEAT</th>
              <th >MILING WHEAT</th>
              <th>DURUM WHEAT</th>
              <th>ANIMAL FEED WHEAT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MOISTURE</td>
              <td>12% MAX</td>
              <td>12% MAX</td>
              <td>12% MAX</td>
            </tr>
            <tr>
              <td>PROTIEN</td>
              <td>12%</td>
              <td>12%</td>
              <td>12%</td>
            </tr>
            <tr>
              <td>FOREIGN MATTER</td>
              <td>1% MAX</td>
              <td>1% MAX</td>
              <td>1% MAX</td>
            </tr>
            <tr>
              <td>PURITY</td>
              <td>99%</td>
              <td>99%</td>
              <td>95%</td>
            </tr>
            <tr>
              <td>DRY GLUTEN</td>
              <td>8.5% MIN</td>
              <td>9.5% MIN</td>
              <td>8% MIN</td>
            </tr>
            <tr>
              <td>DAMAGED KERNEL</td>
              <td>1% MAX</td>
              <td>1% MAX</td>
              <td>1% MAX</td>
            </tr>
            <tr>
              <td>PACKING</td>
              <td colSpan={3}>25KG, 50KG PP BAG OR AS PER BUYER REQUIREMENT</td>
            </tr>
            <tr>
              <td>LOADING</td>
              <td colSpan={3}>25MT IN 20 FT CONTAINER</td>
            </tr>
          </tbody>
        </table>
    </div>
  )
}

export default MilingDurumWheat