import React from 'react'

const Sorghum = () => {
  return (
    <div>
      <table className="responsive-table">
          <thead>
            <tr>
              <th >SORGHUM</th>
              <th >CH 5 SORGHUM/ YELLOW SORGHUM</th>
              <th>RED</th>
              <th>WHITE / SNOW WHITE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MOISTURE</td>
              <td>12% MAX</td>
              <td>12% MAX</td>
              <td>12% MAX</td>
            </tr>
            <tr>
              <td>PROTIEN</td>
              <td>10%</td>
              <td>10%</td>
              <td>10%</td>
            </tr>
            <tr>
              <td>FOREIGN MATTER</td>
              <td>1% MAX</td>
              <td>1% MAX</td>
              <td>1% MAX</td>
            </tr>
            <tr>
              <td>BROKEN</td>
              <td>2% MAX</td>
              <td>2% MAX</td>
              <td>2% MAX</td>
            </tr>
            <tr>
              <td>DISCOLORED</td>
              <td>2% MAX</td>
              <td>2% MAX</td>
              <td>2% MAX</td>
            </tr>
            <tr>
              <td>OTHER FOOD GRADE</td>
              <td>1% MAX</td>
              <td>1% MAX</td>
              <td>1% MAX</td>
            </tr>
            <tr>
              <td>CRUDE FIBER</td>
              <td>2% </td>
              <td>2% </td>
              <td>2% </td>
            </tr>
            <tr>
              <td>SHRIVILLED & IMMATURE</td>
              <td>1% MAX</td>
              <td>1% MAX</td>
              <td>1% MAX</td>
            </tr>
            <tr>
              <td>PACKING</td>
              <td colSpan={3}>25KG,50KG PP BAG OR AS PER BUYER REQUIREMENT</td>
            </tr>
            <tr>
              <td>LOADING</td>
              <td colSpan={3}>24 MT in 20 FT. Container</td>
            </tr>
          </tbody>
        </table>
    </div>
  )
}

export default Sorghum