import React from 'react'

const MustardSeed = () => {
  return (
    <div className='flex lg:flex-row flex-col gap-10'>
        <table className="responsive-table">
        <thead>
          <tr>
            <th>MUSTARD SEEDS</th>
            <th>BLACK</th>
            <th>YELLOW</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TYPE</td>
            <td>SORTEX</td>
            <td>SORTEX</td>

          </tr>
          <tr>
            <td>PURITY</td>
            <td>99.5%</td>
            <td>99.5%</td>

          </tr>
          <tr>
            <td>OTHER COLOR</td>
            <td>0.5%</td>
            <td>0.5%</td>

          </tr>
          <tr>
            <td>OIL CONTENT</td>
            <td colSpan={4}>40-42%</td>

          </tr>
          <tr>
            <td>PLACE OF ORIGIN</td>
            <td colSpan={4}>INDIA</td>

          </tr>
          <tr>
            <td >MOISTURE</td>
            <td colSpan={4}>12%</td>

          </tr>
          <tr>
            <td>PROTEIN</td>
            <td colSpan={4}>10%</td>
          </tr>
        </tbody>
      </table>

      <table className="responsive-table">
        <thead>
          <tr>
            <th>MUSTARD SEEDS</th>

            <th>BLACK</th>
            <th>YELLOW</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TYPE</td>

            <td>SORTEX</td>
            <td>SORTEX</td>
          </tr>
          <tr>
            <td>PURITY</td>

            <td>99%</td>
            <td>99%</td>
          </tr>
          <tr>
            <td>OTHER COLOR</td>

            <td>2%</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>OIL CONTENT</td>
            <td colSpan={4}>40-42%</td>

          </tr>
          <tr>
            <td>PLACE OF ORIGIN</td>
            <td colSpan={4}>INDIA</td>

          </tr>
          <tr>
            <td >MOISTURE</td>
            <td colSpan={4}>12%</td>

          </tr>
          <tr>
            <td>PROTEIN</td>
            <td colSpan={4}>10%</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default MustardSeed