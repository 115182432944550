import React from "react";
import aboutus from "../images/contact/abtus.jpg";
import { Zoom } from "react-awesome-reveal";
import containerImage from "../images/contact/about-header.jpg";
import { Image } from "@chakra-ui/react";

const About = () => {
  return (
    <div>
      {/* <div className=' w-full h-[80px]'></div> */}
      <div className=" w-full h-[450px] relative">
        <img
          src={aboutus}
          alt="about us"
          className=" h-[450px] object-cover bg-center w-full overflow-hidden"
          loading="eager"
          style={{ filter: "blur(6px)" }}
        />
        <div className="absolute top-0 left-0 w-full h-full bg-richblack-700 bg-opacity-60">
          <div className=" md:w-8/12 w-10/12 mx-auto text-white font-inter font-bold text-5xl flex flex-col justify-center h-full text-center">
            <Zoom triggerOnce={true} delay={200}>
              About Us
            </Zoom>
            <p className=" text-base font-normal mt-6">
              <Zoom triggerOnce={true} delay={200}>
                Jay Somnath Traders
              </Zoom>
            </p>
          </div>
        </div>
      </div>

      <div className=" md:w-8/12 w-10/12 mx-auto text-justify">
        <div className=" font-inter text-richblack-700 my-20 w-full">
          <div className=" flex flex-col 2xl:flex-row gap-10 w-full">
            <div className=" w-full 2xl:w-[50%]">
              <p>
                We are the central Exporter of the Fresh Vegetables and Fresh
                Fruits. We significantly bargain in Vegetables like Fresh
                Brinjal, Fresh Capsicum, Fresh Carrot, Fresh Chili, Fresh
                Cluster, Fresh Drumstick, Fresh Bottle Gourd, Fresh Green Peas,
                Fresh Lemon, Fresh Okra, Fresh Parwal, Fresh Red Onion, Fresh
                Sponge Gourd, Fresh Tindora, Fresh Tomato and so on. We
                significantly bargain in Fruits like Fresh Jackfruit, Fresh
                Mangoes, Fresh Guavas, Fresh Pears, Grapes, Fresh Dargon Fruit,
                Fresh Lichi, Fresh Papaya and so on.
              </p>
              <br />
              <p>
                Our wide assortment of Fresh Vegetables and Fresh Fruits is
                developed in controlled condition by utilizing trend setting
                innovation and innocuous manures. Moreover, our Fresh Vegetables
                and Fresh Fruits can be benefited at the market driving costs by
                the customers.
              </p>
              <br />
            </div>
            <div className=" w-full lg:w-[70%] mx-auto 2xl:w-[50%] mb-10 2xl:mb-0">
              <Image src={containerImage} alt="About us" />
            </div>
          </div>
          <p>
            All our Fresh Vegetables and Fresh Fruits are developed in clean
            conditions and are handpicked. They are naturally developed without
            the utilization of any destructive pesticides and synthetic
            concoctions. These Fresh Vegetables and Fresh Fruits are pressed in
            a quality bundling material so they remain crisp for an extensive
            stretch of time. What’s more Fresh Vegetables and Fresh Fruits are
            accessible in modified bundling and at moderate rates. Our wide
            assortment of Fresh Vegetables and Fresh Fruits is developed in
            controlled condition by utilizing cutting edge innovation and
            innocuous composts. Moreover, our Fresh Vegetables and Fresh Fruits
            can be benefited at the market driving costs by the customers.
          </p>
          <br />
          <p>
            We offer extraordinary incentive with our items and administrations.
            Presenting imaginative thoughts in sourcing and trading, we have
            figured out how to improve speed, productivity and on time
            conveyance. Every one of our tasks are bolstered by a skilled,
            dedicated human asset. With a solid accentuation on consumer
            loyalty, we have been putting forth world class items at the most
            focused costs. We are reliably moving in the direction of engaging
            our representatives with brilliant abilities and presenting them
            with the most recent innovation
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
