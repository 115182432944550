import React from "react";

const CuminPowder = () => {
  return (
    <div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>CUMIN POWDER</th>
            <th>SPECS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MOISTURE</td>
            <td>12% Max</td>
          </tr>
          <tr>
            <td>TOTAL ASH</td>
            <td>8% Max</td>
          </tr>
          <tr>
            <td>ACID INSOLUABLE ASH</td>
            <td>1.5%</td>
          </tr>
          <tr>
            <td>VOLATILE OIL</td>
            <td>1%</td>
          </tr>
          <tr>
            <td>MESH SIZE</td>
            <td>Pass 80 Mesh</td>
          </tr>
          <tr>
            <td>MOLD AND YEAST</td>
            <td>100 Cfu/G Max</td>
          </tr>
          <tr>
            <td>COLOR</td>
            <td>Brownish Yellow</td>
          </tr>
          <tr>
            <td>SHELF LIFE</td>
            <td>2 Years if stored Properly</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td>10kg, 20kg,50kg PP bag or Paper Bag</td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td>13 MT in 20 FT and 26 MT in 40 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CuminPowder;
