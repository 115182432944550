import fruitsImage from "../images/fruits.jpg";
import vegetablesImage from "../images/vegetables.jpg";
import grainsImage from "../images/grains.jpg";
import flourImage from "../images/flourr.jpg";
import saltImage from "../images/salt.jpg";
import riceImage from "../images/rice.jpg";
import oilImage from "../images/oilseed.jpg";
import cattleImage from "../images/cattlefeed.jpg";
import groundspicesImage from "../images/spices.jpg";
import wholespicesImage from "../images/wholespices.jpg";
import banana from '../images/fruits/banana.jpg';
import grapes from '../images/fruits/grapes.jpg';
import mangoes from '../images/fruits/mangoes.jpg';
import pomegranate from '../images/fruits/pomegranate.jpg';
import watermelon from '../images/fruits/watermelon.jpg';
import greenchillies from '../images/vegetables/greenchillies.jpg';
import lemon from '../images/vegetables/lemon.jpeg';
import onion from '../images/vegetables/onion.jpg';
import peas from '../images/vegetables/peas.jpg';
import drumstick from '../images/vegetables/drumstick.jpg';
import basmati from '../images/rice/basmati.jpg';
import brokenrice from '../images/rice/brokenrice.jpg';
import nonbasmati from '../images/rice/nonbasmati.jpeg';
import steambasmati from '../images/rice/steambasmati.jpg';
import chichpeas from '../images/grains/chickpea.jpg';
import coriender from '../images/grains/coriender.jpeg';
import corn from '../images/grains/corn.jpg';
import GreenMillet from '../images/grains/Green-Millet.jpg';
import IndainBarley from '../images/grains/IndianBarley.jpg';
import sorughm from '../images/grains/sorughm.jpg';
import wheat from '../images/grains/wheat.jpeg';
import burghul from '../images/flourProduct/burghul.jpeg';
import semolina from '../images/flourProduct/semolina.jpg';
import wheatflour from '../images/flourProduct/wheat.jpg';
import castorfeed from '../images/cattleFeed/castorFeed.jpg';
import rapeseed from '../images/cattleFeed/rapeSeeed.jpg';
import soyabeen from '../images/cattleFeed/soyabeen.JPEG';
import wheatbarn from '../images/cattleFeed/wheatbarn.jpg';
import castorseed from '../images/oilSeed/castorseed.jpg';
import peanutseed from '../images/oilSeed/peanutseed.png';
import Sesameseed from '../images/oilSeed/Sesameseed.png';
import sooyabean from '../images/oilSeed/sooyabean.jpg';
import chili from '../images/groundSpices/chili.jpg';
import coriander from '../images/groundSpices/coriander.jpg';
import cumin from '../images/groundSpices/cumin.jpg';
import turmeric from '../images/groundSpices/turmeric.jpeg';
import yellowpowder from '../images/groundSpices/yellowpowder.jpeg';
import cooriander from '../images/wholeSpices/coriander.jpeg';
import cuminwhole from '../images/wholeSpices/cumin.jpeg';
import fenugreek from '../images/wholeSpices/fennugreek.jpg';
import fennel from '../images/wholeSpices/funnel.png';
import mustardseeds from '../images/wholeSpices/mustardseeds.jpg';
import turmericfinger from '../images/wholeSpices/turmericfinger.jpeg';
import wholechili from '../images/wholeSpices/wholechili.jpg';
import Drumstick from "../components/productDetails/tables/vegetables/Drumstick";
import YellowMaize from "../components/productDetails/tables/grains/YellowMaize";
import Peas from "../components/productDetails/tables/vegetables/Peas";
import IndianBarley from "../components/productDetails/tables/grains/IndianBarley";
import IndianGreenMillet from "../components/productDetails/tables/grains/IndianGreenMillet";
import Sorghum from "../components/productDetails/tables/grains/Sorghum";
import MilingDurumWheat from "../components/productDetails/tables/grains/MilingDurumWheat";
import Chickpeas from "../components/productDetails/tables/grains/Chickpeas";
import CorianderSeeds from "../components/productDetails/tables/grains/CorianderSeeds";
import WheatFlour from "../components/productDetails/tables/flourPrducts/WheatFlour";
import Burghul from "../components/productDetails/tables/flourPrducts/Burghul";
import DurumWheatYellowSemolina from "../components/productDetails/tables/flourPrducts/DurumWheatYellowSemolina";
import Soyabean from "../components/productDetails/tables/oilSeed/Soyabean";
import Peanut from "../components/productDetails/tables/oilSeed/Peanut";
import Sesame from "../components/productDetails/tables/oilSeed/Sesame";
import Castor from "../components/productDetails/tables/oilSeed/Castor";
import WheatBarn from "../components/productDetails/tables/cattleFeed/WheatBarn";
import SoyabeanMeal from "../components/productDetails/tables/cattleFeed/SoyabeanMeal";
import RapeSeedMeal from "../components/productDetails/tables/cattleFeed/RapeSeedMeal";
import CastorSeedMeal from "../components/productDetails/tables/cattleFeed/CastorSeedMeal";
import YellowMustardPowder from "../components/productDetails/tables/groundSpices/YellowMustardPowder";
import CuminPowder from "../components/productDetails/tables/groundSpices/CuminPowder";
import CorianderPowder from "../components/productDetails/tables/groundSpices/CorianderPowder";
import ChiliPowder from "../components/productDetails/tables/groundSpices/ChiliPowder";
import TurmericPoowder from "../components/productDetails/tables/groundSpices/TurmericPoowder";
import WholeChilli from "../components/productDetails/tables/wholeSpices/WholeChilli";
import TurmericFinger from "../components/productDetails/tables/wholeSpices/TurmericFinger";
import MustardSeed from "../components/productDetails/tables/wholeSpices/MustardSeed";
import FenugreekSeed from "../components/productDetails/tables/wholeSpices/FenugreekSeed";
import FennelSeed from "../components/productDetails/tables/wholeSpices/FennelSeed";
import CuminSeed from "../components/productDetails/tables/wholeSpices/CuminSeed";
import CorianderSeed from "../components/productDetails/tables/wholeSpices/CorianderSeed";
import BasmatiRice from "../components/productDetails/tables/rice/BasmatiRice";
import SteamBasmatiRice from "../components/productDetails/tables/rice/SteamBasmatiRice";
import NonBasmatiice from "../components/productDetails/tables/rice/NonBasmatiice";
import BrokenRice from "../components/productDetails/tables/rice/BrokenRice";
import coconutImage from "../images/coconut/coconut.jpg";

export const fruits = {
    name:"Fruits",
    image:fruitsImage,
    description:"Our fruits are sourced from the finest orchards and farms, ensuring they are fresh, juicy, and full of flavor. We offer a wide variety of fruits, each carefully picked and packed to maintain their quality and taste. From seasonal favorites to exotic varieties, our fruits are perfect for any occasion. We prioritize sustainability and work with farmers who practice eco-friendly methods. Enjoy the rich taste and nutritional benefits of our premium fruits.",
    data:[
        {
            id:1,
            name:"Pomegranate",
            image:pomegranate,
            desc:"Fresh Pomegranate is one of the important fruit crops commercially grown. The main commercially grown varieties are Bhagwa , Arkata & Ganesh, Bhagwa is widely known for its soft seed, Dark red colour, and extremely delicious. \n\n Fresh Pomegranate Exporters from India also provide Pomegranate Import at Dubai, Maldives, Australia, USA, Italy, Sweden, UK, Canada , Germany,  SriLanka, Napal, Oman , South Africa, Bangladesh, Vietnam , Malaysia , Myanmar, Bhutan , China and all over world",
            specs:{
                varities:"Bhagwa, Arkata, Ganesh",
                weight:"250 gm + 400 gm",
                taste:"Sweet",
                availability:null,
                packing:"3 kg, 5 kg, 10 kg Cartoon Box",
                size:null,
                skin:null,
                products:null,
            }
        },
        {
            id:2,
            name:"Mangoes",
            image:mangoes,
            desc:"Export and supplier of Alphanso and kesar Mango. Our range of mangos is available with following features and specifications. \n\n Fresh Mangoes Exporters from India also provide Pomegranate Import at Dubai , Sri Lanka, Maldives, Oman ,South Africa, Germany, UK, Sweden, Italy, USA, Australia, Canada and all over worldFresh Mangoes Exporters from India also provide Pomegranate Import at Dubai , Sri Lanka, Maldives, Oman ,South Africa, Germany, UK, Sweden, Italy, USA, Australia, Canada and all over world",
            specs:{
                varities:"Kesar and Alphonso",
                weight:null,
                taste:null,
                availability:"March,  April, May",
                packing:null,
                size:"250 gm to 400 gm Kesar Mangoes, 200 gm to 300 gm Alphonso Mangoes",
                skin:"Light yellow",
                products:null,
            }
        },
        {
            id:3,
            name:"Grapes",
            image:grapes,
            desc:"We export premium grapes, known for their sweet flavor and juicy texture. Our grapes are carefully picked to ensure freshness and quality. \n\n Fresh Grapes Exporters from India  to  Sri Lanka, Maldives, Oman ,South Africa and all over world",
            specs:{
                varities:"Black and Green",
                weight:null,
                taste:null,
                availability:"Feb, March, April",
                packing:"4.5 kg, 5 kg, 8 kg Cartoon Box ",
                size:"16 mm to 20 mm",
                skin:null,
                products:null,
            }
        },
        {
            id:4,
            name:"Banana",
            image:banana,
            desc:"Our bananas are sourced from the best plantations, offering rich taste and nutritional benefits. We ensure that each banana is handpicked and perfectly ripened.",
            specs:{
                varities:"Robusta , Dwarf Cavendish (AAA)",
                weight:null,
                taste:null,
                availability:"September to April ",
                packing:"18 kg Carton Box ",
                size:"6-10 inch long",
                skin:null,
                products:null,
            }
        },
        {
            id:5,
            name:"Watermelon",
            image:watermelon,
            desc:"We provide top-quality watermelons, celebrated for their refreshing sweetness and juiciness. Our watermelons are harvested at peak ripeness to guarantee the best taste.",
            specs:{
                varities:"Suprit Watermelon",
                weight:"5kg to 10kg",
                taste:null,
                availability:"March to May",
                packing:null,
                size:null,
                skin:null,
                products:null,
            }
        },
    ]
}

export const vegetables = {
    name:"vegetables",
    image:vegetablesImage,
    description:"Our vegetables are harvested at their peak to guarantee freshness and flavor. We provide a diverse selection of vegetables, ranging from everyday staples to specialty varieties. Each vegetable is grown with care, ensuring it is free from harmful chemicals and pesticides. Our commitment to quality means you get vegetables that are crisp, vibrant, and nutritious. Whether for cooking, salads, or snacks, our vegetables add wholesome goodness to your meals.",
    data:[
        {
            id:1,
            name:"Onion",
            image:onion,
            desc:"Our onions are renowned for their rich flavor and versatility in cooking. We ensure they are harvested and stored under optimal conditions to maintain freshness. \n\n Fresh Onions Exporters from to Dubai, Sri Lanka, Maldives, Oman ,South Africa and all over world.",
            specs:{
                varities:null,
                weight:null,
                taste:null,
                availability:null,
                packing:"1 kg, 2 kg, 5 kg, 10 kg, 20 kg, Mesh Bag",
                size:"30 mm to 50 mm and 50 mm to 60 mm",
                skin:null,
                products:"Red and white onions",
            }
        },
        {
            id:2,
            name:"Green chillies",
            image:greenchillies,
            desc:"We export vibrant and spicy green chillies, perfect for adding heat to any dish. Our chillies are selected for their superior quality and taste. \n\n Fresh Green Chilles  Exporters from to Dubai, Sri Lanka, Maldives, Oman ,South Africa and all over world.",
            specs:{
                varities:null,
                weight:null,
                taste:null,
                availability:null,
                packing:"3 kg to 5 kg Cartoon Box",
                size:"5 to 10 cm",
                skin:null,
                products:"Fresh Green Chillies",
            }
        },
        {
            id:3,
            name:"Lemon",
            image:lemon,
            desc:"Our lemons are juicy and packed with a tangy zest, ideal for culinary and beverage uses. We ensure they are handpicked to provide the freshest citrus flavor. \n\n Fresh Lemons Chilies Exporters from India to  Dubai at Sri Lanka, Maldives, Oman ,South Africa and all over world.",
            specs:{
                varities:null,
                weight:null,
                taste:null,
                availability:null,
                packing:"10 kg, 20 kg",
                size:"30 mm and 40 mm",
                skin:null,
                products:"Yellow and Green Lemon",
            }
        },
        {
            id:4,
            name:"Drumstick",
            image:drumstick,
            desc:"We offer high-quality drumsticks, known for their nutritional value and unique taste. Our drumsticks are sourced from the best farms to ensure top-notch quality. The pods of the Drumstick or Moringa tree are generally long, smooth-skinned, dark green in color, and three-sided. Drumstick exporter in India export tonnes of Drumsticks every year to different countries in the world. Drumsticks can be used in many recipes and are known for their nutritional qualities. This may be one of the reasons why Drumstick export from India has increased. The Drumstick import from India is now a growing business opportunity.",
            table:<Drumstick/>,
        },
        {
            id:5,
            name:"Peas",
            image:peas,
            desc:"Our peas are sweet, tender, and perfect for a variety of dishes. We take great care in selecting and packaging our peas to preserve their natural flavor and freshness. Due to the growing popularity of peas around the world, the pea exporter in India has to increase the exports accordingly. Peas are healthy to eat and are generally used to many cuisines leading to the increase of pea export from India. Peas can be exported in the fresh or frozen form. The Indian pea exporter, export both these varieties of peas. Besides these, there are many other varieties of pea export from India.",
            table:<Peas/>,
        },
    ]
};

export const grains = {
    name:"Grains",
    image:grainsImage,
    description:"We offer a comprehensive range of high-quality grains, perfect for a variety of culinary uses. Our grains are meticulously cleaned, sorted, and packed to preserve their natural goodness. Sourced from the best fields, they provide essential nutrients and are a staple in many diets. Whether you need grains for baking, cooking, or as a side dish, our products meet the highest standards of quality and taste. Experience the wholesome, earthy flavor of our premium grains.",
    data:[
        {
            id:1,
            name:"Indian Barley",
            image:IndainBarley,
            desc:"Our products are selected from the best farm of barley from prominent crop producing fields where clean and tidy farming methods are implemented. These barley grains are well-known globally for their precious medicinal properties. It is available in a variety of forms like whole barley, hulled barley, pearled barley as well as barley flakes.We offer good quality barley seeds obtained from the best natural farms in the country. Easy to digest, the Indian barley provided by us is healthy to the east and contains rich nutritional content. It is viewed as one of the leading exporters and suppliers of barley from India. This healthy barley is available unlike quantities at nominal prices.",
            table:<IndianBarley/>,
        },
        {
            id:2,
            name:"Yellow Maize",
            image:corn,
            desc:"Yellow Maize is highly demanded in the international markets for its high protein content and healthy attributes. These products are processed in hygienic conditions to provide quality food for Human consumption and animal feed as well as cattle feed. Our Yellow Corn Animal Feed has high nutritional value. We also export White Maize or White Corn. We offer Yellow Maize and White Maize for Animal Feed at very affordable price. Our yellow Maize and White maize is of Fresh and high quality free from impurity and inorganic material. We also export and supply Small Yellow Maize also known as Desi Yellow Maize which is used for Bird feeding in gulf countries.",
            table:<YellowMaize/>,
        },
        {
            id:3,
            name:"Indian Green Millet",
            image:GreenMillet,
            desc:"The finest grade of Millet/Bajra is free from any foul smell and highly suitable for poultry feeding as well. We export product with premium packing that saves its nutritional values for longer period of time. It is highly acknowledged by our valuable clients as it is rich in calcium, protein and iron. It helps to reduce bad cholesterol level in our body. We provide fresh crops of Bold Green Millet from Gujarat and Rajasthan in India. There is different quality for Human Consumption which is Green and Bold & for Animal feed, there Green Grain with brown spots. We are committed to provide at best price and best quality to our client. The provided green millet is processed and cultivated at our vendor’s unit using natural harmless manures. We provide green millet with best specification (Parameters)",
            table:<IndianGreenMillet/>,
        },
        {
            id:4,
            name:"Yellow, Cream and White Sorghum",
            image:sorughm,
            desc:"Sorghum commonly known as Jowar. We are exporting best quality sorghum by directly sourcing from Manufacturer and Farmers. We have large network of Manufacturers across India which helps us to get best price. We offer three varieties of Indian sorghum namely yellow sorghum, white sorghum (both milky and snow white) and Red sorghum.We offer fresh sorghum that has uniform size, shape and color, sound merchantable condition and also conforming to PFA standards.",
            table:<Sorghum/>,
        },
        {
            id:5,
            name:"Milling Wheat and Durum Wheat",
            image:wheat,
            desc:"We are Indian wheat supplier Wheat Grains are a staple food used to make flour for leavened, flat, steamed breads, Biscuits, cookies, cakes, breakfast cereal, pasta, noodles, and couscous. The wheat offered by us is of superior quality. Wheat provided in quality packaging and is customized as per the requests of the clients. Wheat are used in various culinary dishes and made available by us at the most. We export different quality of Wheat such as Milling Wheat, Durum Wheat, Hard Wheat, and Animal Feed.",
            table:<MilingDurumWheat/>,
        },
        {
            id:6,
            name:"Chickpeas",
            image:chichpeas,
            desc:"Chickpeas Are Valued For Its High Fiber And Protein Content. Part Of Legume Family, Chickpea Is One Of The Earliest Cultivated Pulses. Chickpeas Are Popular As Garbanzo Bean In Spain, Ceci Beans In Italy And As Bengal Gram In India. JST Group Deals In Exports And Supplies Of Chickpeas Of Various Origins That Are Generally Sourced From India.JST Group Is Counted Amongst One Of The Most Reputed Chickpeas Exporters In The Global Market. We Procure Chickpeas From Reputed Manufacturers Having Years Of Experience In The Respective Domain. Moreover, We Offer Chickpeas At The Market's Leading Prices.",
            table:<Chickpeas/>,
        },
        {
            id:7,
            name:"Coriander seeds",
            image:coriender,
            desc:"Coriander Seeds, Commonly Known As Dhania Or Cilantro. Coriander Seeds Are Used Around The World As A Condiment Or As A Decoration On The Dishes. JST Group Provides Natural And Fresh Coriander Seeds.Coriander Seeds Are Used In The Process As A Culinary Spice, And It Is Used In Every Kind Of Food For Increasing Taste. Coriander Seeds Are A Good Source Of Dietary Fiber, Iron And Magnesium And It Is Also Used As A Part Of The Various Types Of Drugs. Coriander Seeds Also Has The Medicinal Properties. As It Is A Mild Expectorant, It Is Also Used As An Ingredient Of Medicine And Drugs. It Is Also Used As An Effective Medicine In Treating Diseases Such As Blood Sugar, Digestion, Stomach And Prevent Flatulence, Urinary Tract Infections Etc.",
            table:<CorianderSeeds/>,
        },
    ]
};

export const flourProducts = {
    name:"Flour products",
    image:flourImage,
    description:"Our flour products are made from the finest grains, ensuring a smooth texture and rich taste. We offer a variety of flours, each suitable for different culinary applications, from baking to thickening sauces. Our flours are finely milled to achieve the perfect consistency and are free from additives and preservatives. Whether you are making bread, pastries, or pasta, our flours provide the foundation for delicious, homemade creations. Trust in our quality for consistent and excellent results every time.",
    data:[
        {
            id:1,
            name:"Wheat flour",
            image:wheatflour,
            desc:"Given Flour is finely milled and refined wheat flour, closely resembling cake flour, and used extensively in making Indian fast food, Indian bakery products such as pastries and bread.",
            table:<WheatFlour/>,
        },
        {
            id:2,
            name:"Burghul (Daliya Wheat, Halva Wheat)",
            image:burghul,
            desc:"We are supplying only raw bulgur wheat is made by roasting broken wheat. Its is also know as Daliya, broken wheat, wheat fada. Daliya is also used to make halva.",
            table:<Burghul/>,
        },
        {
            id:3,
            name:"Durum wheat yellow semolina",
            image:semolina,
            desc:"We produce high quality semolina which is used by pasta and macaroni industry. our semolina quality is par with Turkish durum wheat semolina and Italian Durum Wheat Semolina.",
            table:<DurumWheatYellowSemolina/>,
        },
    ]
};

export const rice = {
    name:"Rice",
    image:riceImage,
    description:"We export premium rice varieties known for their aroma, flavor, and texture. Our rice is cultivated in the best paddy fields, ensuring it meets the highest quality standards. Each grain is carefully processed to retain its natural nutrients and taste. Whether you prefer long-grain, short-grain, or specialty rice, we have options to suit all your culinary needs. Enjoy the authentic taste and superior quality of our rice in every meal.",
    data:[
        {
            id:1,
            name:"Basmati rice",
            image:basmati,
            desc:"We offer best FOB, CNF, CIF Rates for Basmati rice with customized packing as per buyer’s requirement. Packing available in different kind of material like PP bags, Jute Bags, Non Woven bags with size starting from 5kg to 50kg packing. ",
            table:<BasmatiRice/>,
        },
        {
            id:2,
            name:"Steam Basmati rice",
            image:steambasmati,
            desc:"Steam Basmati Rice has long length and quick to cook. In order to make our clients completely satisfied we bring in for them, the best quality Steamed Rice.",
            table:<SteamBasmatiRice/>,
        },
        {
            id:3,
            name:"Non basmati rice",
            image:nonbasmati,
            desc:"Non Basmati Rice not only complies with high quality standards but also with better pricing policy, setting high standards in the industry. There are different kinds of Non Basmati rice available in Long Grain and Short Grain as well as Medium Grain.",
            table:<NonBasmatiice/>,
        },
        {
            id:4,
            name:"Broken rice grade wise",
            image:brokenrice,
            desc:"We process our broken rice through completely hygienic process so to retain its natural properties. We also offer best quality boiled broken rice which has high demand in the market.",
            table:<BrokenRice/>,
        },
    ]
};

// export const salt = {
//     name:"Salt",
//     image:saltImage,
//     description:"Triple Refined Free Flow Iodized Salt & High Purity Table Salt are produced from carefully harvested Sea Salt with our state associate production facility which is registered under ISI (by Indian Standard Bureau) situated near Kandla Port in Gujarat State, India. Standardization and Quality is being consistently maintained by our Professional Quality Control Staff. In addition to this, we also offer Iodized powder salt that is commonly called table salt. It is to be used in homes and restaurants as an additive in various types of cooking. We offer only the purest natural form of salt that has been hygienically cleaned and packed in packages of various capacities. Our special packaging helps to retain the flavor and freshness of salt. Addition of iodine has been shown to reduce some types of diseases. Iodized Powder Salt is known for its wide range of health benefits and its pristine quality. Iodized salt is used to prepare various dishes & thus finds application in restaurants, hotels and households. Offered at reasonable price, our range is suitably packed to intact its freshness and purity. Further, these are available in different quantities according to the requirements of clients."
// }

export const oilSeed = {
    name:"Oil seeds",
    image:oilImage,
    description:"Our oil seeds are sourced from top-quality farms, ensuring they are rich in nutrients and flavor. We provide a range of oil seeds suitable for culinary and industrial uses, each carefully processed to maintain their natural properties. Our seeds are free from contaminants and are packed to preserve their freshness. Whether for cooking oils, snacks, or other applications, our oil seeds deliver consistent quality. Trust our oil seeds to provide the best in health benefits and taste.",
    data:[
        {
            id:1,
            name:"soyabean seed",
            image:sooyabean,
            desc:"Soya Bean Seed under perfect atmospheric conditions by our highly qualified professionals. Our offered products are highly appreciated for their optimum quality and reliability. ",
            table:<Soyabean/>,
        },
        {
            id:2,
            name:"Peanut seed",
            image:peanutseed,
            desc:" JST is Exporter of Peanut, which is also known as Ground nut kernel, is a seed with high oil & protein contents. It is a very vital food crop, which is consumed in many different forms.",
            table:<Peanut/>,
        },
        {
            id:3,
            name:"Natural Sesame Seeds",
            image:Sesameseed,
            desc:"It is carefully cleaned before it is hulled or sold. It is extensively used for the application in bakery products such as bread, bread sticks, cookies, candies, pasta, vegetables and curry dishes. ",
            table:<Sesame/>,
        },
        {
            id:4,
            name:"Castor Seeds",
            image:castorseed,
            desc:"With the assistance of our skilled professionals, we are engaged in providing high grade Castor Seeds Products. These seeds are cultivated and processed as per the international standards under the utmost favorable conditions. ",
            table:<Castor/>,
        },
    ]

};

export const cattleFeed = {
    name:"Cattle feed",
    image:cattleImage,
    description:"Our cattle feed is formulated to meet the nutritional needs of livestock, promoting their health and productivity. We use high-quality ingredients to ensure our feed is rich in essential vitamins and minerals. Each batch is carefully balanced to provide optimal growth and energy for your cattle. Our feed is designed to be easily digestible, improving the overall well-being of your animals. Choose our cattle feed for reliable and effective nutrition that supports sustainable farming practices.",
    data:[
        {
            id:1,
            name:"Wheat barn",
            image:wheatbarn,
            desc:"JST is exporter of Wheat Bran. It is a byproduct generated while extracting flour from the Wheat Grain. Technically speaking there’s no product called Animal Feed or Human Food. When buyers use term called Animal Feed Wheat Bran, the producers use adulterants to reduce the cost of the product and offer the price for the same.",
            table:<WheatBarn/>,
        },
        {
            id:2,
            name:"Soya bean meal",
            image:soyabeen,
            desc:"The Soy bean Meal, we offer, is processed using quality Soybean by adopting quality methods by high tech machinery & experienced Staff. The Soybean Meal, we offer, is widely demanded for feeding animals due to the rich nutritional content. The clients can avail the Soybean Meal from us in varied quantity packs.",
            table:<SoyabeanMeal/>,
        },
        {
            id:3,
            name:"Rape seed meal",
            image:rapeseed,
            desc:"Our Rapeseed Meal Is Produced From The Best Rape Seed which is used after oil extraction. We Are known As One Of Prominent Rapeseed Meal Exporters And Suppliers In The International Market.  We Are One Of The Leading Suppliers And Exporters Of Rapeseed Meal To Various Countries",
            table:<RapeSeedMeal/>,
        },
        {
            id:4,
            name:"castor seed meal",
            image:castorfeed,
            desc:"Castor meal – the residue obtained from castor cake by the solvent Extraction process – is one of the most versatile natural manures. It is truly organic manure which enhances the fertility of the soil without causing any damage or decay. ",
            table:<CastorSeedMeal/>,
        },
    ]
};

export const groundSpices = {
    name:"Ground spices",
    image:groundspicesImage,
    description:"Our ground spices are made from the finest whole spices, ensuring a rich and authentic flavor. Each spice is carefully selected, dried, and ground to preserve its natural aroma and taste. We offer a wide range of ground spices, perfect for enhancing the flavor of any dish. Our spices are free from artificial additives and are packed to maintain their freshness. Add a touch of our premium ground spices to your cooking for a burst of natural flavor.",
    data:[
        {
            id:1,
            name:"Yellow Mustard Powder",
            image:yellowpowder,
            desc:"JST is Mustard powder Exporter from India. Yellow Mustard powder is the powdered form of yellow whole mustard. It is used as a spice to provide hot and strong flavor to the food. Mustard seed powder is used in the preparation of sauces, cheese and egg dishes, pickles, curries, etc. ",
            table:<YellowMustardPowder/>,
        },
        {
            id:2,
            name:"Cumin Powder",
            image:cumin,
            desc:"The Cumin Powder offered by us is prepared from chosen batches of Cumin seeds of plants grown under optimum conditions.Cumin Powder is processed from best Quality Cumin Seeds. It is our job to make sure that color, taste, flavor is mentioned.Finely processed and cleaned, the Cumin Powder made available by us is an excellent addition to a variety of dishes",
            table:<CuminPowder/>,
        },
        {
            id:3,
            name:"Coriander Powder",
            image:coriander,
            desc:"JST is supplier engaged in offering the superior quality Coriander Powder. The offered powder is finely processed using the optimal grade dried coriander and sophisticated technology under the strict vigilance of our adept professionals. ",
            table:<CorianderPowder/>,
        },
        {
            id:4,
            name:"Chili Powder",
            image:chili,
            desc:"JST is suppliers of a qualitative range of Chili Powder. The offered powder is processed using high grade ingredients and sophisticated technology under the supervision of our skilled team of professionals. Our offered range is highly demanded by clients spanning across the nation due to its rich pungent flavor, great taste, purity and longer shelf life.",
            table:<ChiliPowder/>,
        },
        {
            id:5,
            name:"Turmeric Powder",
            image:turmeric,
            desc:"The offered powder is processed using premium quality ingredients and sophisticated technology as per the set industry norms under utmost hygienic conditions. Appreciated among our esteemed clients for their scrumptious flavor, purity and optimum quality, these are highly demanded in the national market.",
            table:<TurmericPoowder/>,
        }
    ]
};

export const wholeSpices = {
    name:"Whole spices",
    image:wholespicesImage,
    description:"We export a variety of whole spices, celebrated for their potent aroma and flavor. Our whole spices are sourced from the best regions, ensuring they are fresh and high-quality. Each spice is carefully handled to preserve its natural properties and is perfect for grinding, toasting, or infusing. Our selection includes both common and exotic spices, catering to all culinary needs. Enhance your dishes with the robust and authentic taste of our whole spices.",
    data:[
        {
            id:1,
            name:"Whole Chilli",
            image:wholechili,
            desc:"Our range of Red chillies is available with stem and without stem. These Red Chillies  is procured from farmers, who adopt various modern and traditional methods in cultivating fresh range of chillies The whole red chilli is used extensively for flavoring several indian as well as continental dishes. ",
            table:<WholeChilli/>,
        },
        {
            id:2,
            name:"Turmeric Finger",
            image:turmericfinger,
            desc:"Turmeric is used to flavor and to colour foodstuffs. It is a principal ingredient in curry powder. Turmeric oleoresin is used in brine pickles and to some extent in mayonnaise and relish formulations, non-alcoholic beverages, gelatins, butter and cheese etc.",
            table:<TurmericFinger/>,
        },
        {
            id:3,
            name:"Mustard Seeds",
            image:mustardseeds,
            desc:"JST is supplier and exporter of Mustard seeds. We source our product from most trusted vendors in our country. We are keen in product quality, hygienic processing and packing. Mustard seeds are the proverbially small seeds of the various mustard plants. The seeds are about 1 mm in diameter, and may be colored from yellowish white to black.",
            table:<MustardSeed/>,
        },
        {
            id:4,
            name:"Fenugreek Seeds",
            image:fenugreek,
            desc:"JST Fenugreek seeds is used as a spice and fenugreek leaves are eaten as vegetable in India, China and the Middle East for centuries. The practitioners of Ayurvedic and Traditional Chinese Medicine have used and documented Methi seeds for its many medicinal uses .",
            table:<FenugreekSeed/>,
        },
        {
            id:5,
            name:"Fennel Seeds",
            image:fennel,
            desc:"JST provides our clients with a range of fennel seeds, which is to flavor breads, cakes and confectionery. These are also an ingredient of Chinese five spices. Fennel has an anise like flavor but is more aromatic, sweeter and less pungent. While greenish seeds are much softer and naturally sweeter.",
            table:<FennelSeed/>,
        },
        {
            id:6,
            name:"Cumin Seeds",
            image:cuminwhole,
            desc:"JST is the largest Exporter, & Supplier of Cumin Seeds from India. We clean, process & sort under hygienic conditions as per European Union norms with highly advancedsorted machines. The oblong, brown-coloured cumin seed is one of the most popular spices in the world, thanks to its distinctive aroma, and warmly bitter flavour.",
            table:<CuminSeed/>,
        },
        {
            id:7,
            name:"Coriander Seeds",
            image:cooriander,
            desc:"JST holds immense specialization in serving the patrons with an optimum quality gamut of Coriander Seeds. The offered Coriander Seeds have a lemon citrus flavor and prominently used in all cuisines for adding a distinctive flavor. Coriander is an important spice crop having a prime position in flavouring food.",
            table:<CorianderSeed/>,
        },
    ]
};

export const coconuts = {
    name:"Cocont",
    image:coconutImage,
    description:"Coconuts are versatile and nutritious, valued for their delicious water, creamy flesh, and various by-products. They are a rich source of healthy fats, vitamins, and minerals, making them a staple in many diets around the world. Our coconuts are carefully harvested and processed to ensure the highest quality and freshness. Whether you use them for cooking, baking, or beauty products, our coconuts deliver exceptional flavor and numerous health benefits. Enjoy the tropical goodness and natural richness of our premium coconuts.",
    data:[
        {
            id:1,
            name:"Wheat barn",
            image:wheatbarn,
            desc:"JST is exporter of Wheat Bran. It is a byproduct generated while extracting flour from the Wheat Grain. Technically speaking there’s no product called Animal Feed or Human Food. When buyers use term called Animal Feed Wheat Bran, the producers use adulterants to reduce the cost of the product and offer the price for the same.",
            specs:{
                varities:"Kesar and Alphonso",
                weight:null,
                taste:null,
                availability:"March,  April, May",
                packing:null,
                size:"250 gm to 400 gm Kesar Mangoes, 200 gm to 300 gm Alphonso Mangoes",
                skin:"Light yellow",
                products:null,
                load:null,
                seaPort:null,
            }
        },
        {
            id:2,
            name:"Soya bean meal",
            image:soyabeen,
            desc:"The Soy bean Meal, we offer, is processed using quality Soybean by adopting quality methods by high tech machinery & experienced Staff. The Soybean Meal, we offer, is widely demanded for feeding animals due to the rich nutritional content. The clients can avail the Soybean Meal from us in varied quantity packs.",
            specs:{
                varities:"Kesar and Alphonso",
                weight:null,
                taste:null,
                availability:"March,  April, May",
                packing:null,
                size:"250 gm to 400 gm Kesar Mangoes, 200 gm to 300 gm Alphonso Mangoes",
                skin:"Light yellow",
                products:null,
                load:null,
                seaPort:null,
            }
        },
        {
            id:3,
            name:"Rape seed meal",
            image:rapeseed,
            desc:"Our Rapeseed Meal Is Produced From The Best Rape Seed which is used after oil extraction. We Are known As One Of Prominent Rapeseed Meal Exporters And Suppliers In The International Market.  We Are One Of The Leading Suppliers And Exporters Of Rapeseed Meal To Various Countries",
            specs:{
                varities:"Kesar and Alphonso",
                weight:null,
                taste:null,
                availability:"March,  April, May",
                packing:null,
                size:"250 gm to 400 gm Kesar Mangoes, 200 gm to 300 gm Alphonso Mangoes",
                skin:"Light yellow",
                products:null,
                load:null,
                seaPort:null,
            }
        }
    ]
};