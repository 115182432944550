import React from "react";
import coconut from "../images/coconut/coconut.jpg";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const CoconutPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className=" w-full h-[80px]"></div>

      <div className=" w-full h-[60px] bg-richblack-800 text-richblack-25">
        <p className=" md:w-8/12 w-10/12 mx-auto font-inter flex items-center h-full breadcrumb-container">
          <Breadcrumb
            spacing="8px"
            separator={<MdKeyboardArrowRight color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink onClick={() => navigate("/")}>
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink>Products</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>Semi Husked Coconut</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </p>
      </div>

      <div className=" md:w-8/12 w-11/12 mx-auto my-20">
        <div className=" flex lg:flex-row flex-col gap-10 justify-between items-center w-full mb-20">
          <div>
            <img
              src={coconut}
              alt={"coconut"}
              className=" lg:w-[800px] rounded-lg aspect-video object-coover"
            />
          </div>
          <div className=" md:w-[100%] font-inter text-lg text-richblack-800 text-justify">
            <div className=" text-3xl font-inter font-bold mb-8 text-left">
            Semi Husked Coconut
            </div>
            Backed by the team of experienced professionals, we are processing and supplying Semi Husked Coconut. Processed using fresh coconuts, our semi husked coconut is highly admired by the clients for their purity, fine texture, rich taste and high nutritional value. 
            <br /><br/>
            These coconuts are grown under the hygienic condition and processed with utmost care at our vendors' unit. Further, these are available in tamper proof packaging to retain their properties and freshness for longer period of time. Moreover, we provide these semi husked coconut to our clients at market leading prices.
          </div>
        </div>

        <div className=" w-full my-14 font-inter">
            <div className=" font-bold text-2xl mb-5">
                Product Specifications
            </div>
            <div>
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Color : </span>
                  Brown, well matured
                </p>
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Weight : </span>
                  500 grams to 65 grams
                </p>
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Size : </span>
                  13 inches
                </p>
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Origin : </span>
                  India
                </p>
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Packing : </span>
                  25 pieces per bag
                </p>
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Load : </span>
                  Ability in 40ft container is 45000 pieces in 1850 bags
                </p>
                <p className=" mb-2">
                  <span className=" font-bold text-lg">Sea port : </span>
                  Chennai, Cochin or tuticorion
                </p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default CoconutPage;
