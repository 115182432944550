import React from 'react';
import WhyUsCard from './WhyUsCard';
import customerSatisfaction from '../../images/customerSatisfaction.png';
import deliverTime from "../../images/deliverTime.png";
import experience from "../../images/experienceProfessional.png";
import highQuality from "../../images/highQuality.png";
import price from '../../images/price.png';
import relation from '../../images/relations.png';
import { Fade } from 'react-awesome-reveal';

const WhatWeDo = () => {
  return (
    <div className=' flex items-center justify-center bg-fixed bg-parallax bg-cover '>
        <div className='w-full h-full bg-richblack-800 bg-opacity-55 py-24'>
            <div className=' text-4xl font-bold font-inter text-center text-white mb-16'>
                Why Choose Us
            </div>
            
            <div className=' lg:w-9/12 w-10/12 mx-auto'>
                <div className=' grid lg:grid-cols-2 grid-cols-1 gap-16 items-stretch place-content-stretch place-items-stretch'>
                    <Fade direction='left' delay={100} duration={500} triggerOnce={true} fraction={0.2}>
                        <WhyUsCard
                            cardTitle={"High Quality"}
                            desc={"Our products are crafted with precision and care, ensuring the highest quality in every detail. We stand by the excellence of our brand, providing you with only the best."}
                            image={highQuality}
                        />
                    </Fade>
                    <Fade direction='right' delay={100} duration={500} triggerOnce={true} fraction={0.2}>
                        <WhyUsCard
                            cardTitle={"Experience & Professional"}
                            desc={"With years of experience and a team of dedicated professionals, we bring expertise and a refined touch to everything we do. Trust in our proven track record and professional approach."}
                            image={experience}
                        />
                    </Fade>
                    <Fade  direction='left' delay={100} duration={500} triggerOnce={true} fraction={0.2}>
                        <WhyUsCard
                            cardTitle={"Customer satisfaction"}
                            desc={"Your satisfaction is our top priority. We go above and beyond to meet your needs and exceed your expectations, ensuring a delightful experience with every interaction."}
                            image={customerSatisfaction}
                        />
                    </Fade>
                    <Fade direction='right' delay={100} duration={500} triggerOnce={true} fraction={0.2}>
                        <WhyUsCard
                            cardTitle={"We deliver on time"}
                            desc={"Timeliness is key, and we pride ourselves on delivering your orders promptly. Count on us for reliable and punctual service, every time."}
                            image={deliverTime}
                        />
                    </Fade>
                    <Fade direction='left' delay={100} duration={500} triggerOnce={true} fraction={0.2}>
                        <WhyUsCard
                            cardTitle={"Competetive Pricing"}
                            desc={"We offer competitive pricing without compromising on quality, ensuring you get the best value for your investment."}
                            image={price}
                        />
                    </Fade>
                    <Fade direction='right' delay={100} duration={500} triggerOnce={true} fraction={0.2}>
                        <WhyUsCard
                            cardTitle={"End-to-End logistics support"}
                            desc={"Our comprehensive end-to-end logistics support guarantees a seamless and efficient process from production to delivery, providing you with peace of mind."}
                            image={relation}
                        />
                    </Fade>
                </div>
            </div>
        </div>
    </div>
  )
};

export default WhatWeDo;