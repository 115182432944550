import React from 'react';
import CardProduct from './CardProduct';
import fruits from '../../images/fruits.jpg';
import vegetables from '../../images/vegetables.jpg';
import flour from '../../images/flourr.jpg';
import cattlefeed from '../../images/cattlefeed.jpg';
import salt from '../../images/salt.jpg';
import grains from '../../images/grains.jpg';
import rice from '../../images/rice.jpg';
import spices from '../../images/spices.jpg';
import wholespices from '../../images/wholespices.jpg';
import oilseed from '../../images/oilseed.jpg';
import coconut from '../../images/coconut/coconut.jpg';

const WhatWeExport = () => {
  return (
    <div className=' lg:w-9/12 w-10/12 mx-auto py-24'>
        <div className=' font-inter text-4xl font-bold text-center mb-10'>What We Export</div>
        <div className=' grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 place-items-center place-content-center gap-10 items-stretch '>
            <CardProduct
                cardTitle={"Fresh Fruits"}
                desc={"We export a wide variety of fresh, juicy fruits sourced from the finest orchards. Our fruits are carefully selected to ensure optimal taste and quality."}
                image={fruits}
                urlLink={"/products/fruits"}
            />
            <CardProduct
                cardTitle={"Fresh Vegetables"}
                desc={"Our selection of fresh vegetables is harvested from the best farms. We guarantee crisp, nutritious, and vibrant produce in every shipment."}
                image={vegetables}
                urlLink={"/products/vegetables"}
            />
            <CardProduct
                cardTitle={"Grains"}
                desc={"We provide high-quality grains, meticulously cleaned and packaged. Our grains are sourced from the most fertile regions to ensure premium quality."}
                image={grains}
                urlLink={"/products/grains"}
            />
            <CardProduct
                cardTitle={"Flour products"}
                desc={"Our range of flour products is made from the finest grains. We offer high-grade flour that meets all your baking and cooking needs."}
                image={flour}
                urlLink={"/products/flourProducts"}
            />
            <CardProduct
                cardTitle={"Rice"}
                desc={"We export premium rice varieties known for their aroma, flavor, and texture. Our rice is cultivated in the best paddy fields for superior quality."}
                image={rice}
                urlLink={"/products/rice"}
            />
            <CardProduct
                cardTitle={"Oil Seed"}
                desc={"We offer top-quality oil seeds, ideal for various culinary and industrial uses. Our oil seeds are carefully processed to maintain their nutritional value."}
                image={oilseed}
                urlLink={"/products/oilSeed"}
            />
            <CardProduct
                cardTitle={"Salt"}
                desc={"Our salt is sourced from pristine salt pans, offering purity and taste. We provide both edible and industrial-grade salt to meet diverse needs."}
                image={salt}
                urlLink={"/product/salt"}
            />
            <CardProduct
                cardTitle={"Cattle Feed"}
                desc={"We supply nutritious cattle feed formulated for optimal livestock health. Our feed is balanced and enriched with essential nutrients."}
                image={cattlefeed}
                urlLink={"/products/cattleFeed"}
            />
            <CardProduct
                cardTitle={"Ground Spices"}
                desc={"Our spices are sourced from the best farms, ensuring rich aroma and flavor. We offer a wide range of ground and blended spices to enhance any dish."}
                image={spices}
                urlLink={"/products/groundSpices"}
            />
            <CardProduct
                cardTitle={"Whole Spices"}
                desc={"We export a variety of whole spices, renowned for their freshness and potency. Our whole spices are carefully selected and packed to retain their natural essence."}
                image={wholespices}
                urlLink={"/products/wholeSpices"}
            />
            <CardProduct
                cardTitle={"Semi Husked Coconut"}
                desc={"We are processing and supplying Semi Husked Coconut. Our semi husked coconut is highly admired by the clients for their purity, fine texture, rich taste and high nutritional value."}
                image={coconut}
                urlLink={"/product/coconut"}
            />
        </div>
    </div>
  )
};

export default WhatWeExport;