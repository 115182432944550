import React from "react";

const WholeChilli = () => {
  return (
    <div className=" flex lg:flex-row flex-col gap-10">
      <table className="responsive-table">
        <thead>
          <tr>
            <th>WHOLE CHILLI</th>
            <th>BYADGI</th>
            <th>INDAM-5</th>
            <th>SANAM</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TYPE</td>
            <td>BYADGI</td>
            <td>INDAM-5</td>
            <td>SANAM</td>
          </tr>
          <tr>
            <td>MOISTURE</td>
            <td>12%</td>
            <td>12%</td>
            <td>12%</td>
          </tr>
          <tr>
            <td>COLOR VALUE</td>
            <td>90-100 ASTA</td>
            <td>60-80 ASTA</td>
            <td>35-40 ASTA</td>
          </tr>
          <tr>
            <td>ADMIXTURE</td>
            <td>0.5%</td>
            <td>1%</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>HEAT VALUE</td>
            <td>8000-15000 SHU</td>
            <td>35000-6000 SHU</td>
            <td>15000-25000 SHU</td>
          </tr>
          <tr>
            <td>FOREIGN MATTER</td>
            <td>2% MAX</td>
            <td>2% MAX</td>
            <td>2% MAX</td>
          </tr>
          <tr>
            <td>BROKEN CHILLI</td>
            <td>5% MAX</td>
            <td>5% MAX</td>
            <td>5% MAX</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={3}>25kgs Gunny Bag Or As Per Buyer Required</td>
          </tr>
          <tr>
            <td>INSPECTION</td>
            <td colSpan={3}>QSS, GEOCHEM or As per Requirement</td>
          </tr>
        </tbody>
      </table>

      <table className="responsive-table">
        <thead>
          <tr>
            <th>WHOLE CHILLI</th>
            <th>TEJA</th>
            <th>WRINKAL</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TYPE</td>
            <td>TEJA</td>
            <td>WRINKAL</td>
          </tr>
          <tr>
            <td>MOISTURE</td>
            <td>12%</td>
            <td>12%</td>
          </tr>
          <tr>
            <td>COLOR VALUE</td>
            <td>75-85 ASTA</td>
            <td>80-90 ASTA</td>
          </tr>
          <tr>
            <td>ADMIXTURE</td>
            <td>1%</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>HEAT VALUE</td>
            <td>75000-85000 SHU</td>
            <td>25000-35000 SHU</td>
          </tr>
          <tr>
            <td>FOREIGN MATTER</td>
            <td>2% MAX</td>
            <td>2% MAX</td>
          </tr>
          <tr>
            <td>BROKEN CHILLI</td>
            <td>5% MAX</td>
            <td>5% MAX</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={2}>25kgs Gunny Bag Or As Per Buyer Required</td>
          </tr>
          <tr>
            <td>INSPECTION</td>
            <td colSpan={2}>QSS, GEOCHEM or As per Requirement</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WholeChilli;
