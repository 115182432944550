import React from "react";

const FenugreekSeed = () => {
  return (
    <div className="flex lg:flex-row flex-col gap-10">
      <table className="responsive-table">
        <thead>
          <tr>
            <th>FENUGREEK SEEDS</th>
            <th colSpan={2}>YELLOWISH-BROWN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ASH CONTENT</td>
            <td>9%</td>
            <td>9%</td>
          </tr>
          <tr>
            <td>ACID INSOLUABLE ASH</td>
            <td>1.25%</td>
            <td>1.25%</td>
          </tr>
          <tr>
            <td>PURITY</td>
            <td>99%</td>
            <td>98%</td>
          </tr>
          <tr>
            <td>SALMONELLA</td>
            <td colSpan={3}>ABSENT</td>
          </tr>
          <tr>
            <td>PLACE OF ORIGIN</td>
            <td colSpan={3}>INDIA</td>
          </tr>
          <tr>
            <td>PROCESSING</td>
            <td colSpan={3}>SUN-DRIED(RAW)</td>
          </tr>
          <tr>
            <td>MOISTURE</td>
            <td colSpan={3}>5%-8% MAX</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={3}>
              25 kg, 50 kg HDPE or Paper Bag ( Consumer Packing Available )
            </td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td colSpan={3}>24 MT in 20 FT</td>
          </tr>
        </tbody>
      </table>

      <table className="responsive-table">
        <thead>
          <tr>
            <th>FENUGREEK SEEDS</th>
            <th>LIGHT YELLOW</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ASH CONTENT</td>
            <td>9%</td>
          </tr>
          <tr>
            <td>ACID INSOLUABLE ASH</td>
            <td>1.25%</td>
          </tr>
          <tr>
            <td>PURITY</td>
            <td>96%</td>
          </tr>
          <tr>
            <td>SALMONELLA</td>
            <td colSpan={3}>ABSENT</td>
          </tr>
          <tr>
            <td>PLACE OF ORIGIN</td>
            <td colSpan={3}>INDIA</td>
          </tr>
          <tr>
            <td>PROCESSING</td>
            <td colSpan={3}>SUN-DRIED(RAW)</td>
          </tr>
          <tr>
            <td>MOISTURE</td>
            <td colSpan={3}>5%-8% MAX</td>
          </tr>
          <tr>
            <td>PACKING</td>
            <td colSpan={3}>
              25 kg, 50 kg HDPE or Paper Bag ( Consumer Packing Available )
            </td>
          </tr>
          <tr>
            <td>LOADING</td>
            <td colSpan={3}>24 MT in 20 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FenugreekSeed;
