import React from "react";
import spiceBoardLogo from "../images/certificates/certi1.png";
import apedaLogo from "../images/certificates/certi2.jpg";
import certi3 from "../images/certificates/certi3.png";
import certi4 from "../images/certificates/certi4.png";
import certi5 from "../images/certificates/certi5.jpg";
import certi6 from "../images/certificates/certi6.png";
import certi7 from "../images/certificates/certi7.png";
import certi8 from "../images/certificates/certi8.png";
import certi9 from "../images/certificates/certi9.jpg";
import certi10 from "../images/certificates/certi10.jpg";
import certi11 from "../images/certificates/certi11.jpg";
import { Fade } from "react-awesome-reveal";

const CertificatesPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-12">
      <div className=" w-full h-[80px]"></div>
      <div className="max-w-6xl w-[90vw] mx-auto text-center">
        <h1 className="text-4xl font-bold mb-14">Our Certificates</h1>
        <p className="mb-12 text-lg text-gray-700">
          We are proud to be certified by the Spices Board India and APEDA.
          These certifications demonstrate our commitment to quality and
          excellence in our products.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <Fade delay={100} duration={500} triggerOnce={true} fraction={0.2}>
            <div className="bg-white p-8 shadow-lg rounded-lg">
              <img
                src={spiceBoardLogo}
                alt="Spices Board India"
                className="mx-auto mb-4"
              />
              <h2 className="text-2xl font-semibold">Spices Board India</h2>
              <p className="text-gray-600 mt-4">
                The Spices Board India is responsible for the export promotion
                of the spices listed in the schedule to the Spices Board Act
                1986.
              </p>
            </div>
          </Fade>
          <Fade delay={100} duration={500} triggerOnce={true} fraction={0.5}>
            <div className="bg-white p-8 shadow-lg rounded-lg mt-10 sm:mt-0">
              <img src={apedaLogo} alt="APEDA" className="mx-auto mb-4" />
              <h2 className="text-2xl font-semibold">APEDA</h2>
              <p className="text-gray-600 mt-4">
                The Agricultural and Processed Food Products Export Development
                Authority (APEDA) promotes the export of agricultural products
                from India.
              </p>
            </div>
          </Fade>
          <Fade delay={100} duration={500} triggerOnce={true} fraction={0.5}>
            <div className="bg-white p-8 shadow-lg rounded-lg mt-10 sm:mt-0">
              <img src={certi3} alt="MSME" className="mx-auto mb-4" />
              <h2 className="text-2xl font-semibold">MSME</h2>
              <p className="text-gray-600 mt-4">
                The Ministry of Micro, Small & Medium Enterprises promotes the
                development of MSMEs in India.
              </p>
            </div>
          </Fade>
          <Fade delay={100} duration={500} triggerOnce={true} fraction={0.5}>
            <div className="bg-white p-8 shadow-lg rounded-lg mt-10 sm:mt-0">
              <img src={certi5} alt="FICCI" className="mx-auto mb-4" />
              <h2 className="text-2xl font-semibold">FICCI</h2>
              <p className="text-gray-600 mt-4">
                The Federation of Indian Chambers of Commerce and Industry
                (FICCI) is an association of business organizations in India.
              </p>
            </div>
          </Fade>
          <Fade delay={100} duration={500} triggerOnce={true} fraction={0.5}>
            <div className="bg-white p-8 shadow-lg rounded-lg mt-10 sm:mt-0">
              <img src={certi6} alt="GMP Quality" className="mx-auto mb-4" />
              <h2 className="text-2xl font-semibold">GMP Quality</h2>
              <p className="text-gray-600 mt-4">
                Good Manufacturing Practices (GMP) ensure products are
                consistently produced and controlled according to quality
                standards.
              </p>
            </div>
          </Fade>
          <Fade delay={100} duration={500} triggerOnce={true} fraction={0.5}>
            <div className="bg-white p-8 shadow-lg rounded-lg mt-10 sm:mt-0">
              <img
                src={certi7}
                alt="Halal Certified"
                className="mx-auto mb-4"
              />
              <h2 className="text-2xl font-semibold">Halal Certified</h2>
              <p className="text-gray-600 mt-4">
                Halal certification ensures that products meet Islamic dietary
                laws and are permissible for consumption by Muslims.
              </p>
            </div>
          </Fade>
          <Fade delay={100} duration={500} triggerOnce={true} fraction={0.5}>
            <div className="bg-white p-8 shadow-lg rounded-lg mt-10 sm:mt-0">
              <img src={certi8} alt="FDA" className="mx-auto mb-4" />
              <h2 className="text-2xl font-semibold">FDA</h2>
              <p className="text-gray-600 mt-4">
                The Food and Drug Administration (FDA) is responsible for
                protecting public health by ensuring the safety and efficacy of
                food and drugs.
              </p>
            </div>
          </Fade>
          <Fade delay={100} duration={500} triggerOnce={true} fraction={0.5}>
            <div className="bg-white p-8 shadow-lg rounded-lg mt-10 sm:mt-0">
              <img src={certi9} alt="DGFT" className="mx-auto mb-4" />
              <h2 className="text-2xl font-semibold">DGFT</h2>
              <p className="text-gray-600 mt-4">
                The Directorate General of Foreign Trade (DGFT) is responsible
                for formulating and implementing the foreign trade policy of
                India.
              </p>
            </div>
          </Fade>
          <Fade delay={100} duration={500} triggerOnce={true} fraction={0.5}>
            <div className="bg-white p-8 shadow-lg rounded-lg mt-10 sm:mt-0">
              <img src={certi10} alt="FIEO" className="mx-auto mb-4" />
              <h2 className="text-2xl font-semibold">FIEO</h2>
              <p className="text-gray-600 mt-4">
                The Federation of Indian Export Organisations (FIEO) represents
                the Indian entrepreneur's spirit of enterprise in the global
                market.
              </p>
            </div>
          </Fade>
          <Fade delay={100} duration={500} triggerOnce={true} fraction={0.5}>
            <div className="bg-white p-8 shadow-lg rounded-lg mt-10 sm:mt-0">
              <img
                src={certi11}
                alt="Development Board"
                className="mx-auto mb-4"
              />
              <h2 className="text-2xl font-semibold">Development Board</h2>
              <p className="text-gray-600 mt-4">
                The Development Board supports the sustainable growth and
                development of agriculture in India.
              </p>
            </div>
          </Fade>
          <Fade delay={100} duration={500} triggerOnce={true} fraction={0.5}>
            <div className="bg-white p-8 shadow-lg rounded-lg mt-10 sm:mt-0">
              <img
                src={certi4}
                alt="Certification 11"
                className="mx-auto mb-4"
              />
              <h2 className="text-2xl font-semibold">Aadhar Udyog Aadhar</h2>
              <p className="text-gray-600 mt-4">
              Aadhar Udyog Aadhar is a unique identification for Micro, Small, and Medium Enterprises (MSMEs) in India.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default CertificatesPage;
