import React from "react";

const SaltTable = () => {
  return (
    <div>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>TYPE</th>
            <th>TRIPLE REFINED IODIZED SALT</th>
            <th>HIGH PURITY TABLE SALT</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Sodium Chloride (NaCl)</td>
            <td>98.50% min</td>
            <td>99.50% min</td>
          </tr>
          <tr>
            <td>Iodine</td>
            <td>30 PPM min</td>
            <td>30 PPM min</td>
          </tr>
          <tr>
            <td>Calcium (Ca)</td>
            <td>0.12% max</td>
            <td>0.04% max</td>
          </tr>
          <tr>
            <td>Magnesium (Mg)</td>
            <td>0.04% max</td>
            <td>0.04% max</td>
          </tr>
          <tr>
            <td>Sulphate (SO4)</td>
            <td>0.60% max</td>
            <td>0.20% max</td>
          </tr>
          <tr>
            <td>Moisture</td>
            <td>0.25% max</td>
            <td>0.25% max</td>
          </tr>
          <tr>
            <td>Water Insoluble</td>
            <td>0.20% max</td>
            <td>0.20% max</td>
          </tr>
          <tr>
            <td>Silica (Anti cracking Agent)</td>
            <td>0.10% max</td>
            <td>0.10% max</td>
          </tr>
          <tr>
            <td>Whiteness Index</td>
            <td>90% min</td>
            <td>90% min</td>
          </tr>
          <tr>
            <td>Iron (Fe)</td>
            <td>5 PPM max</td>
            <td>5 PPM max</td>
          </tr>
          <tr>
            <td>Packing</td>
            <td colSpan={2}>
              Multi wall plain new PP bags of 25 kgs or 50 kgs
            </td>
          </tr>
          <tr>
            <td>Container Loading</td>
            <td colSpan={2}>28 MT per 20 FT</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SaltTable;
