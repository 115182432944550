import React from 'react'

const Chickpeas = () => {
  return (
    <div>
      <div className="container">

        <div className="table1">
          <div className="row">
            <div className="cell header">Caliber count</div>
            <div className="cell">
              75-80, 58-60, 44-46, 42-44, 40-42
            </div>
          </div>
          <div className="row">
            <div className="cell header">Broken %</div>
            <div className="cell">1% MAX</div>
          </div>
          <div className="row">
            <div className="cell header">Split %</div>
            <div className="cell">2% MAX</div>
          </div>
          <div className="row">
            <div className="cell header">Worm Biten %</div>
            <div className="cell">1% MAX</div>
          </div>
          <div className="row">
            <div className="cell header">Aborted %</div>
            <div className="cell">
              1% MAX
            </div>
          </div>
          <div className="row">
            <div className="cell header">Damage %</div>
            <div className="cell">
              2% MAX
            </div>
          </div>
          <div className="row">
            <div className="cell header">Foreign matter %</div>
            <div className="cell">
              1% MAX
            </div>
          </div>
          <div className="row">
            <div className="cell header">Moisture %</div>
            <div className="cell">
              7-10% MAX
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chickpeas